import { parseCurrency } from "utils"

  
const profilesTitles = {
    male:'Menino',
    female:'Menina'
}

export const parseProfile = item => {
    return {
        id: item.id,
        name: item.name,
        avatar:{ image: item?.picture?.url },
        gender:{ title:profilesTitles[item.gender], type:item.gender }
    }
}

export const parseProfileImage = item => {
    return {
        id: item.id,
        name: item.name,
        image: item.url 
    }
}
 


const plans = {
    'Mensal':{
        type:'montly_plan',
        title:'JKids Mensal',
        image:'/background/background-5.png',
        action:`JKids Mensal - R$ 24,90 - Por Mês`,
        text:`Este plano é automaticamente renovado a cada mês`,
        renew:`R$24,90/mês`,
        variant:{ primary:true },
        value:24.9,
        installment: false
    },
    'Anual':{
        type:'yearly_plan',
        title:'JKids Anual',
        image:'/background/background-6.png',
        action:`JKids Anual - R$ 29,90 - Por Mês`,
        text:`Este plano é automaticamente renovado a cada 12 meses`,
        renew:`R$218,80/ano`,
        variant:{ secondary:true }, 
        value:218.8,
        installment: true
    }
}

export const parsePlan = (item, backgrounds) => ({
    ...plans[item.title],
    ...item,
    text: item.descriptor,
    action:`Plano ${item.title} - ${ parseCurrency(item.title === 'Anual' ? parseFloat(item.value / 1)  : item.value) } -- `,
    renew:`${ parseCurrency(item.value) }/${ item.title === 'Anual' ? 'ano' : 'mês' }`,
    image: backgrounds?.find(fnd => fnd.locale === plans[item.title]?.type)?.url
})











const categoriesOption = {
    'Filme':{
        title:'Filme',
        color:'blue'
    },
    'Série':{
        title:'Série',
        color:'yellow'
    },

    'Musical':{
        title:'Musical',
        color:'blue'
    },
    'Educativo':{
        title:'Educativo',
        color:'green'
    }
}

export const parseBanner = item => { 
    return { 
        id: item?.featured_show?.data?.id,
        title: item?.featured_show?.data?.attributes?.name,
        image: item?.featured_banner?.data?.attributes?.url,
        category: categoriesOption['Série'] || {}
    }
}


const categoryIcons = {
    // 'Conteúdos': { icon:'smile' },
    // 'Educativos': { icon:'art' },
    // 'Filmes': { icon:'popcorn' }
}


export const parseCategory = item => { 
    return { 
        title: item?.attributes?.name, 
        id: item?.id, 
        icon: categoryIcons[item?.attributes?.name] || false
    }
}


const collectionResources = {
    'Meus Favoritos':{ icon:'heart', image: '/images/users/male-1.png' },
    'As 20 Músicas + Vistas':{ icon:'sound', image: '/images/users/male-2.png' },
    'Cante e Dance':{ icon:'dance', image: '/images/users/female-3.png' },
}

export const parseCollection = item =>  {
    return {
        id: item?.id, 
        title: item?.attributes?.name, 
        ...(collectionResources[item?.attributes?.name] || {}),
        videos: item?.attributes?.stream_shows?.data?.length ? item?.attributes?.stream_shows?.data?.map(parseVideo) : [],
        image: item?.attributes?.icon?.data?.attributes?.url ? item?.attributes?.icon?.data?.attributes?.url : null
    }
}

export const parseVideo = item => {
    return { 
        id: item?.id, 
        title: item?.attributes?.name || item?.name, 
        description: item?.attributes?.description || item?.description, 
        cover: item?.attributes?.thumbnail?.data?.attributes?.url || item?.thumbnail?.url, 
        // category_id:1 
    }
}

export const parseVideoShow = item => {
    return { 
        ...item,
        title:item.name,
        cover: item?.wide_picture?.url , 
        thumb: item?.thumbnail?.url , 
        // category_id:1 
    }
}
