import styled from 'styled-components'  
 

export const VideosContent = styled.div.attrs({ 
})`             
`;
export const VideosContentTitle = styled.div.attrs({ 
})`          
    font-size: 20px;
    font-weight: bold;
    color: var(--white-color);    
    margin-left: 180px;   
    margin-bottom: 20px;   

    @media(max-width: 767px){
        margin-left: 100px; 
    }
    @media(max-width: 499px){
        margin-left: 20px; 
    } 
    @media(max-width: 419px){
        margin-left: 10px; 
    }
`;
export const VideosContentRow = styled.div.attrs({ 
})`        
    display: flex;     
    align-items: center;  
    ${
        props => props.gap ? `
            gap: 0 20px;
        ` : ` 
        `
    }


    ${
        props => props.wrapper ? `
            flex-wrap: wrap;
            gap: 20px;
            padding-left: 180px;

            @media(max-width: 767px){
                padding-left: 100px;
            }
            @media(max-width: 499px){
                padding-left: 20px; 
            }
            @media(max-width: 419px){
                padding-left: 10px; 
            }
        ` : ``
    }
`;
export const VideosContentCircle = styled.div.attrs({ 
})`             
    min-width: 70px;
    min-height: 70px;
    border-radius: 35px;

    margin: 0 54px;

    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    ${
        props => props.image ? `
            background: url(${props.image}) no-repeat center center / cover;
            margin: 0 34px 0 54px;
        ` : ``
    }

    @media(max-width: 767px){
        margin: 0 14px;
        ${
            props => props.image ? ` 
                margin: 0 0 0 14px;
            ` : ``
        }
    } 


    @media(max-width: 499px){
        min-width: 50px;
        min-height: 50px;
        border-radius: 25px;
    }

`;
export const VideosContentCircleSpace = styled.div.attrs({ 
})`             
    min-width: 70px;
    min-height: 70px;
    border-radius: 35px;

    margin: 0 34px 0 54px;
`;
export const VideosContentCircleIcon = styled.img.attrs(props => ({ 
    src:`/icons/${ props.icon }.svg`   
}))`             
`;
export const VideoContentVideo = styled.div.attrs({ 
})`           
    min-width: 270px;
    height: 151px; 

    background: var(--white-color) url(${ props => props.image ? props.image : '' }) no-repeat center center / cover;

    cursor: pointer;
    &:hover{
        transform: scale(1.2);
    }

    @media(max-width: 499px){
        min-width: 180px;
        height: 101px; 
    }

    @media(max-width: 419px){
        min-width: 120px;
        height: 61px; 
    }
`;
export const VideoContentVideoDecoration = styled.div.attrs({ 
})`             
`;


export const VideosContentRowScroll = styled.div.attrs({ 
})`             
    overflow: auto;
    padding: 0 0 40px 0;
`;
