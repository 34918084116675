import { GET, POST, PUT, DELETE } from './api'


export const ReadFavorites = async (profileId) => {
    return await GET(`/favorites?profile=${ profileId }`, true);
}  

export const AddFavorite = async (params, profileId) => {
    return await POST(`/favorites?profile=${ profileId }`, params, true);
}  

export const RemoveFavorite = async (id, profileId) => {
    return await DELETE(`/favorites/${ id }?profile=${ profileId }`, true);
}  