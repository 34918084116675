import React, { useContext, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText,
    PageResendAction,
    PageActions,
    BigInput,

    ContentCheck,
    CheckItem,
    CheckItemIcon

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import { exposeStrapiError } from "utils";
import { UpdateMe } from "services/account";

export default function DashboardAccountConfirmEmail(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user, setUser } = useContext(CoreContext)

    const [ loading, setLoading ] = useState(false)
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const save = async () => {
        setLoading(true)
        const result = await UpdateMe({ data: form })
        setLoading(false)    
        if(result && !exposeStrapiError(result)){
            toast.success('Email atualizado com sucesso') ; 
            setUser({ ...user, email: form.email })
            history.goBack()
            history.goBack()
        } 

    } 

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Alterar E-mail</PageSubtitle>
                            <PageText>
                                Você vai substituir o e-mail usado para entrar no JoinKids.
                            </PageText>
                            <PageText>
                                E-mail atual: <b>{ user?.email }</b>
                            </PageText>
                            <BigInput placeholder={'Novo e-mail'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                            <Check>
                                    <big><b>Sair de todos os dispositivos</b></big>
                                    <p>Depois de alteração do e-mail, sua sessão será encerrada neste dispositivo. Pode levar até quatro horas para sair dos dispositivos restantes</p>
                            </Check> 
                            <PageActions>
                                <Button primary onClick={save} loading={loading}>Salvar</Button>
                                <Button primary outline onClick={() => history.goBack()}>Cancelar</Button>
                            </PageActions> 
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}