import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'reactstrap';
import { Container, NavButtons, NavText, ImageContainer, StyledImage } from './styled';
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'


// Importe as imagens usando caminhos relativos à raiz do projeto
const joinkids_image1 = '/images/Joinkids_depoimento_cliente01.png';
const joinkids_image2 = '/images/Joinkids_depoimento_cliente02.png';
const joinkids_image3 = '/images/Joinkids_depoimento_cliente03.png';
const joinkids_image4 = '/images/Joinkids_depoimento_cliente04.png';
const joinkids_image5 = '/images/Joinkids_depoimento_cliente05.png';
const joinkids_image6 = '/images/Joinkids_depoimento_cliente06.png';
const joinkids_image7 = '/images/Joinkids_depoimento_cliente07.png';
const joinkids_image8 = '/images/Joinkids_depoimento_cliente08.png';

const images = [joinkids_image1, joinkids_image2, joinkids_image3, joinkids_image4, joinkids_image5, joinkids_image6, joinkids_image7, joinkids_image8];
const Appok = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Função para avançar automaticamente
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Mudar a cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  // Verifica se a tela é grande (acima de 1024px)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  // Verifica se a tela é pequena (abaixo de 1024px)
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  // Define quantas imagens mostrar de acordo com o tamanho da tela
  const slidesToShow = isDesktopOrLaptop ? 3 : 1.2;
  const slideWidthPercentage = isDesktopOrLaptop ? 33.33 : 83.33; // 25% para 4 imagens e 66.67% para 1.5 imagens

  return (
    <Container>
         {/*<Row>*/}
                       <Col md={{size:6}}>
                            <CommomCentred spaced>
                                <CommomTitle>O que estão falando sobre a JoinKids</CommomTitle>
                                {/* <CommomText>A JoinKids agradece o apoio dessas empresas parceiras: Smilinguido, Turma do Cristaozinho, Minha Vida é uma Viagem, Tia Ceceu, 3 Palavrinhas, Crianças Diante do Trono, Rebeca Nemer Kids, Fingerprint Ministery, Sheepelitos, Bíblia na Cozinha e Play com o Pai</CommomText> */}
                                
                            </CommomCentred>
                        </Col>
        {/*</Row> */}
        {/* <h1>Bem-vindo ao Nosso Carrossel</h1>
        <p>Deslize para ver nossas imagens, com exibição automática de novas imagens a cada 5 segundos.</p>
 */}
      <Carousel
        selectedItem={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        showThumbs={false}
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        emulateTouch={true}
        showStatus={false}
        swipeable={true}
        centerMode={true}
        centerSlidePercentage={slideWidthPercentage} // // Ajusta conforme o tamanho da tela
        showIndicators={false}
      >
        {images.map((src, index) => (
          <ImageContainer key={index}>
            <StyledImage src={src} alt={`Image ${index + 1}`} />
          </ImageContainer>
        ))}
      </Carousel>
      {/* <NavButtons>
        <button onClick={() => setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 2 : prevIndex - 1))}>
          <NavText>{'<'}</NavText>
        </button>
        <button onClick={() => setActiveIndex((prevIndex) => (prevIndex === images.length - 2 ? 0 : prevIndex + 1))}>
          <NavText>{'>'}</NavText>
        </button>
      </NavButtons> */}
                
    </Container>
  );
};

export default Appok;
