import { CoreContext } from "context/CoreContext";
import React, { useContext, useEffect, useRef, useState } from "react";  
import { Load } from "ui/styled";

import {     
 

    VideoNavigationContent,
    VideoNavAction,
    VideoNavActionIcon,
    VideoNavProgress,
    VideoNavProgressIn,
    VideoNavProgressDecoration,
    VideoNavProgressDecorationText, 

} from "./styled"; 
export default function DashboardPlayerProgress({item}){    

    const { toggleFavorite, isFavorite, favoriting } = useContext(CoreContext)

    return ( 
        <> 

            <VideoNavigationContent>
                <VideoNavAction active={isFavorite(item)} onClick={() => toggleFavorite(item)}>
                    {
                        favoriting ? <Load /> :
                        <VideoNavActionIcon icon={'like'}/>
                    }
                </VideoNavAction>
                <VideoNavProgress />
                {/* <VideoNavProgress>
                    <VideoNavProgressIn progress={`0%`} />
                    <VideoNavProgressDecoration>
                        <VideoNavProgressDecorationText>
                            <b>00:00</b>/00:00
                        </VideoNavProgressDecorationText>
                    </VideoNavProgressDecoration>
                </VideoNavProgress>
                <VideoNavAction>
                    <VideoNavActionIcon icon={'audio_on'}/>
                </VideoNavAction> */}
            </VideoNavigationContent>
 
        </>
    );

}