import React, { useEffect, useState } from "react";  

import {     
    DashboardSearcher,
    SearchItem,
    SearchItemIcon,
    SearcherInput, 
} from "./styled"; 

export default function SearchInput({ placeholder, onSearch, outline, searched, onChange }){   

    const [value, setValue] = useState('')
    
    const clearInput = () => {
        setValue('')
        onChange({ target:{ value:'' } })
    }

    const handleChange = e => {
        setValue(e.target.value)
        if(onChange && typeof onChange === 'function'){
            onChange(e)
        }
    }

    useEffect(() => {
        if(searched && searched?.length){
            setValue(searched)
        }
    }, [searched])

    return ( 
        <> 
            <DashboardSearcher outline={outline}>
                <SearchItem onClick={() => onSearch(value)}>
                    <SearchItemIcon icon={'search'} />
                </SearchItem>
                <SearcherInput placeholder={placeholder} value={value} onChange={handleChange} onKeyUp={e => e.keyCode === 13 ? onSearch(value) : null } />
                {
                    value?.length ? 
                        <SearchItem onClick={clearInput}>
                            <SearchItemIcon icon={'close-white'} />
                        </SearchItem>
                    : null
                }
            </DashboardSearcher>
        </>
    );

}