import styled from 'styled-components'  
 

export const BodyMenu = styled.div.attrs({ 
})`             
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 0 20px;

    margin-left: 160px;   
    cursor: pointer;
    &:hover{
        
    }

    @media(max-width:767px){
        margin-left: 80px;   
    }

    @media(max-width: 499px){
        margin-left: 0px; 
    }
`;
export const BodyMenuItem = styled.div.attrs({ 
})`             
    font-size: 16px;
    color: var(--white-color);    
    padding: 7px 20px;
    ${
        props => props.active ? `
            font-weight: bold;
            border-bottom: 2px solid var(--secondary-color);
        ` : ``
    }
    position: relative;


    @media(max-width: 419px){
        padding: 7px 10px;
    }
`;
export const BodyMenuItemIcon = styled.img.attrs(props => ({ 
    src: `/icons/${props.icon}.svg`
}))`             
    margin-left: 8px;
`;

 

export const MenuScroll = styled.div.attrs({ 
})`              
`; 


export const MenuMenu = styled.div.attrs({ 
})`              
    position: absolute;
    width: 200px;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
`;
    
export const MenuItem = styled.div.attrs({ 
})`              
    padding: 14px 20px;
    font-size: 14px;
    font-weight: bold;
    background: var(--white-color);
    color: var(--black-color);

    &:hover{
        background: var(--primary-color);
        color: var(--white-color);
    }
`;
