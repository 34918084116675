import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
 



export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;
 


export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 20px;
`;
 
export const DownloadsText = styled.div.attrs({ 
})`           
    font-size: 18px;
    line-height: 1.2em;
    color: var(--darkprimary-color);    

    text-align: center;
`;


export const BannerOut = styled.div.attrs({ 
})`            
    // min-height: 720px;
    // width: 100%;

    // background: var(--white-color); 
    // mix-blend-mode: multiply;
    min-height: 105vh;
    width: 50%;

    background: var(--white-color); 
    mix-blend-mode: multiply;
`; 
// antes o min-height estava 105vh. Eu alterei todos para 720px        
export const BannerContent = styled.div.attrs({ 
})`           
    min-height: 205vh;
    width: 100%; 

    

    position: absolute;
    top:  255px;
    left: 0;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    max-width: 325px;
    margin: 0 auto;
    
`; 


export const BannerCenterContent = styled.div.attrs({ 
})`            
    display: flex;
    flex-direction: column;
    align-items: center;
`; 

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 10px auto 10px;
    flex:1;
`; 



export const BannerLogo = styled.img.attrs({ 
    src:'/icons/logo_lancamento.svg'
})`           
    // margin: 65px auto 20px;
    // margin: -45px auto 10px;
    margin: 0 0 0 auto 20px;
    width: 100%;
    max-width: 210px;

    `; 


export const BannerText = styled.div.attrs({ 
})`           
    hover: 0
    font-size: 18px;
    line-height: 1.8em;
    color: var(--white-color);    
    text-align: center;
`; 

export const BannerText2 = styled.div.attrs({ 
})`           
font-size: 14px;
line-height: 1.5em;
color: var(--white-color);    
text-align: center;
margin: 0 auto;
max-width: 340px;
`;

export const BannerSubtext = styled.div.attrs({ 
})`           
    font-size: 14px;
    line-height: 2em;
    color: var(--white-color);    
    text-align: center;
    margin: 0 auto;
    max-width: 340px;
`; 

export const ColorLink = styled.div.attrs({ 
})`           
    color: var(--yellow-color);
    text-decoration: underline;  
`; 

export const BannerImage = styled.div.attrs({ 
})`           

    // position: relative;
    // min-height: 720px;
    // width: 100%;
    // background: url(/images/banner_topo.jpg) no-repeat top +35% right +30% / cover;
    position: relative;
    min-height: 720px;
    width: 100%;
    // background: url(/images/banner_topo_GIFanim_02.gif) no-repeat top +35% right +30% / cover;
    background: url(/images/banner_topo.jpg) no-repeat top / cover;
    
`;