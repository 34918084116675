import styled from 'styled-components'  

const headerHeight = 105
 
export const DashboardPage = styled.div.attrs({ 
})`            
`;

export const DashboardBody = styled.div.attrs({ 
})`       
    min-height: calc(100vh - ${ headerHeight }px);
    max-height: calc(100vh - ${ headerHeight }px);
    background: var(--white-color);

    display: flex;
    align-items: flex-start;     
    overflow:auto;
`;

export const DashboardBodyContent = styled.div.attrs({ 
})`             
    padding: 35px 20px;
    padding: 0;
    width: 100%; 
    min-height: calc(100vh - ${ headerHeight }px);


    background: var(--white-color);
    ${
        props => props.image ? `
            background: url(${ props.image }) no-repeat center center / cover;
        ` : ``
    }
    ${
        props => props.primary ? `
            background: var(--darkprimary-color);
        ` : ``
    }
`;

export const Content = styled.div.attrs({ 
})`           
`; 