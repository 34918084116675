import React, { useContext, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,
 
    PageSubtitle,
    PageText, 
    PageActions,
    BigInput, 

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import { UpdatePassword } from "services/account";
import { exposeStrapiError } from "utils";

export default function DashboardAccountCreatePassword(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const [ loading, setLoading ] = useState(false)
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const save = async () => {
        setLoading(true)
        const result = await UpdatePassword({ data: form })
        setLoading(false)    
        if(result && !exposeStrapiError(result)){
            toast.success('Senha atualizada com sucesso') ;
            history.goBack()
        } 

    }

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Criar uma nova senha</PageSubtitle> 
                            <BigInput placeholder={'Senha atual'} type={'password'} value={formValue('previous_password')} onChange={e => changeForm(e.target.value, 'previous_password')} />
                            <BigInput placeholder={'Nova senha'} type={'password'} value={formValue('new_password')} onChange={e => changeForm(e.target.value, 'new_password')} />
                            <PageText>
                                Use no mínimo seis caracteres (com distinção entre maiúscula e minúscula) com pelo menos um número ou caractere especial.
                            </PageText>
                            <Check>
                                <big><b>Sair de todos os dispositivos</b></big>
                                <p>Depois de alteração de senha, sua sessão será encerrada neste dispositivo. Pode levar até quatro horas para sair dos dispositivos restantes</p>
                            </Check> 
                            <PageActions>
                                <Button primary onClick={save} loading={loading}>Salvar</Button>
                                <Button primary outline onClick={() => history.goBack()}>Cancelar</Button>
                            </PageActions> 
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}