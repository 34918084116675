import styled from 'styled-components'  
  
import InputMask from 'react-input-mask';
import MaterialInput from 'components/Input'

export const CentredContent = styled.div.attrs({ 
})`  
    padding-top: 215px;
    max-width: 325px;      
    margin: 0 auto;
    ${
        props => props.big ? `
            padding-top: 310px;
        ` : ``
    }
`;

export const FormContent = styled.div.attrs({ 
})`           
    max-width: 325px;
    margin: 0 auto;
    ${
        props => props.big ? `
            max-width: 425px;
        ` : ``
    } 
`;
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: var(--primary-color); 
    font-weight: bold;
    font-size: 14px; 

    margin: 50px 0 10px;
    ${
        props => props.secondary ? `
            color: var(--secondary-color);
        ` : ``
    }
`;
export const FormText = styled.div.attrs({ 
})`           
    color: var(--grey-color);
    font-size: 18px; 
    font-weight: bold;

    margin-bottom: 30px;
    ${
        props => props.white ? `
            color: var(--white-color);
        ` : ``
    }
    ${
        props => props.label ? `
            margin: 18px 0 12px;
        ` : ``
    }
`;




export const SelectedProfile = styled.div.attrs({ 
})`            
    display: flex;
    align-items: center;
    margin-top: 30px;
`;
export const SelectedProfileImage = styled.div.attrs({ 
})`            
    width: 80px;
    height: 80px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--lg-color);

    ${
        props => props.image ? `
            background: var(--lg-color) url(${ props.image }) no-repeat center center / cover ;
        ` : ``
    }

    margin-right: 20px;
`;
export const SelectedProfileImageIcon = styled.img.attrs({ 
    src: '/images/users/face.png',
    width: 53
})`            
`;
export const SelectedProfileTitle = styled.div.attrs({ 
})`            
    color: var(--black-color);
    font-size: 30px; 
    font-weight: bold;
`;
export const SelectProfileWrapper = styled.div.attrs({ 
})`            
    padding: 30px 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;
export const SelectProfileImage = styled.div.attrs({ 
})`           
    width: 80px;
    height: 80px;
    border-radius: 40px; 
    background: url(${ props => props.image }) no-repeat center center / cover ;

    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--lightshadow-color);
    }

    ${
        props => props.active ? `
            border: 3px solid var(--primary-color);
        ` : ``
    }
`;

export const RowChoose = styled.div.attrs({ 
})`           
    display: flex;
    gap: 0 20px;
`;




export const DateInput = (props) => (
    <InputMask mask="99/99/9999" maskChar={null}  value={props.value} onChange={props.onChange}>
      {(inputProps) => <MaterialInput {...inputProps} outline type="tel" />}
    </InputMask>
);