import React, { useContext, useEffect, useRef, useState } from "react";  

import {     

    VideoTouch,
    
    ContainerVideoPlayer,
    ContainerVideo,


    VideoHeader,
    VideoHeaderSection,
    VideoHeaderBack,
    VideoHeaderImage,
    VideoHeaderContent,
    VideoHeaderSubtitle,
    VideoHeaderTitle,
    VideoHeaderAction,
    VideoHeaderActionIcon,

    VideoCenterContent,
    VideoCenterAction,
    VideoCenterPlay,
    VideoCenterPlayIcon,
 

    VideoBottomContent,
    VideoBottomAction,
    VideoBottomActionIcon,
    VideoBottomWrapper,
    VideoBottomVideo

} from "./styled";
 
import { CoreContext } from "context/CoreContext"; 

import ContainerProfile from "containers/Profile";
import { Container } from "reactstrap";
import DashboardPlayerProgress from "components/Dashboard/PlayerProgress";
import { useHistory, useParams } from "react-router-dom";
import { Load } from "ui/styled";
import { parseVideo, parseVideoShow } from "utils/parser";
import { ReadShowDetails, ReadPreviewsShow } from "services/catalog";
import { AddHistory } from "services/account";

import ReactHlsPlayer from 'react-hls-player';
import { exposeStrapiError } from "utils";

export default function DashboardPlayer(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { video_id, running } = useParams()
    const { currentProfile } = useContext(CoreContext)

    const videoRef = useRef()
    const [playing, setPlaying] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const [previewMode, setPreviewMode] = useState(false)

    const playPause = () => { 
        if( videoUrl && typeof videoRef?.current?.play === 'function' ){
            videoRef.current.play()
        }
    }

    const togglePreview = () => {
        setPreviewMode(!previewMode)
    }  

    useEffect(() => {
        if(videoUrl && running){ setTimeout(playPause, 100) ;}
    }, [videoUrl, running]);

    
    const [loading, setLoading] = useState(false)
    const [relateds, setRelateds] = useState([])
    const [details, setDetails] = useState({})

    const [nextStream, setNextStream] = useState(null)
    const [prevStream, setPrevStream] = useState(null)

    const loadVideo = async () => {
        setLoading(true)
        const result = await ReadShowDetails(video_id)
        const prevShow = await ReadPreviewsShow(video_id)

        if(!exposeStrapiError(result) && result?.related_shows?.length){
            setRelateds(result.related_shows?.map(parseVideo))
        }

        if(result?.stream_master_file?.url){
            setVideoUrl(`${ result?.stream_master_file?.url }`)
        }else{  
            navigate('dashboard/sign')
        } 

        if(result.id){ setDetails(parseVideoShow(result)) }

        if(result?.next_stream?.id){
            setNextStream(result?.next_stream)
        }

        if(prevShow?.data?.[0]?.id){
            setPrevStream(prevShow?.data?.[0])
        }

        setLoading(false)
        await AddHistory(currentProfile?.id, { data:{ stream_show:video_id } }) 
    }


    const nextShow = () => { 
        navigate(`dashboard/player/${ nextStream.id }/running`);
    }
    
    const prevShow = () => {  
        navigate(`dashboard/player/${ prevStream.id }/running`)
    }
    
    useEffect(() => {
        if(video_id && currentProfile){ loadVideo() ;}
    }, [video_id, currentProfile])


    useEffect(() => {
        if( videoUrl && typeof videoRef?.current?.addEventListener === 'function' ){
                const fireOnVideoEnd = () => {  
                    navigate(`dashboard/player/${ nextStream.id }/running`);
                }
                videoRef.current.addEventListener('ended', fireOnVideoEnd);
            return () => {
                videoRef.current.removeEventListener('ended', fireOnVideoEnd); 
            }
        }
    }, [ nextStream, videoUrl, videoRef?.current ]);

    return ( 
        <>
            <ContainerProfile fluid>   
                <ContainerVideoPlayer>

                        {/* 
                        <video ref={videoRef} autoPlay={false} height={'100%'} onPause={() => setPlaying(false)} style={{ objectFit:'fill' }}>
                            <source src={'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4'} type="video/mp4" />
                            <source src={"https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"} type="application/x-mpegURL" />
                        </video> 
                        */}

                        {
                            videoUrl ? 
                                <ReactHlsPlayer
                                    playerRef={videoRef} 
                                    src={videoUrl}
                                    autoPlay={!!running}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    onPause={() => setPlaying(false) }
                                    onPlay={() => setPlaying(true) } 
                                    style={{ objectFit:'fill' }}
                                />
                            : null
                        }
                </ContainerVideoPlayer>
                {
                    playing ? null : 
                    <ContainerVideo> 
                        
                        <VideoHeader preview={previewMode}>
                            <VideoHeaderSection>
                                <VideoHeaderBack onClick={() => navigate('dashboard')} />
                                <VideoHeaderImage image={details?.thumb} />
                                <VideoHeaderContent>
                                    <VideoHeaderSubtitle>{ details?.title }</VideoHeaderSubtitle>
                                    <VideoHeaderTitle>{ details?.description }</VideoHeaderTitle>
                                </VideoHeaderContent>
                            </VideoHeaderSection>
                            <VideoHeaderSection flex>
                                {/* { previewMode ? <DashboardPlayerProgress  item={{ id: video_id }}  /> : null } */}
                                <VideoHeaderAction onClick={togglePreview}>
                                    <VideoHeaderActionIcon active={previewMode} />
                                </VideoHeaderAction>
                            </VideoHeaderSection>
                        </VideoHeader>

                        {
                            previewMode ? null : <>
                                <VideoCenterContent>
                                    {
                                        !prevStream ? null :
                                        <VideoCenterAction onClick={prevShow} />
                                    }
                                    <VideoCenterPlay onClick={playPause}>
                                        <VideoCenterPlayIcon />
                                    </VideoCenterPlay>
                                    {
                                        !nextStream ? null :
                                        <VideoCenterAction next onClick={nextShow} />
                                    }
                                </VideoCenterContent>
                                <DashboardPlayerProgress item={{ id: video_id }} />
                            </>
                        }

                        <VideoBottomContent>
                            <VideoBottomAction onClick={togglePreview}>
                                <VideoBottomActionIcon active={previewMode} />
                            </VideoBottomAction>
                            <Container>
                                <VideoBottomWrapper>
                                    { loading ? <Load white marginBottom={20} /> : null }
                                    {
                                        relateds.map((item, key) => 
                                            <VideoBottomVideo key={key} image={item.cover} onClick={() => navigate(`dashboard/player/${ item.id }`)} />
                                        )
                                    }
                                </VideoBottomWrapper>
                            </Container>
                        </VideoBottomContent>
                        
                    </ContainerVideo>
                } 
            </ContainerProfile> 
        </>
    );

}