import styled from 'styled-components'  
 
export const SelectContainer = styled.div.attrs({ 
})`            
    width: 100%;
`;
 
export const WrapperOptions = styled.div.attrs({ 
})`            
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
`;
 
export const OptionItem = styled.div.attrs({ 
})`            
    color: var(--primary-color); 
    font-size: 14px;  

    padding: 3px 6px ;
    border: .5px solid var(--primary-color);


    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
`; 
 
export const TouchInput = styled.div.attrs({ 
})`           
    cursor: pointer;
    width: 100%;

    border: .5px solid var(--lightgrey-color);
    border-radius: 5px;
    overflow: hidden;
    height: 52px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;


    color: var(--lightgrey-color);
    font-size: 14px;

    &:hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`; 

export const TouchInputIcon = styled.img.attrs({ 
    src: '/icons/chevron-secondary.svg'
})`            
    transition: all .3s ease;
    ${
        props => props.open ? `
        ` : `
            transform: rotate(90deg);
        `
    }
`; 