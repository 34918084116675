import React, { useContext, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,
 
    PageSubtitle,
    PageText, 
    PageActions,
    BigInput, 


    FormWrap,
    FormSpacer,

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import Input from "components/Input";
import { UpdatePlanCard } from "services/plans";
import { exposeStrapiError, GetCardType } from "utils";

export default function DashboardAccountChangeCreditCard(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [ form, setForm ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const save = async () => {
        setLoading(true)
        const CardNumber = formValue('card_number')?.replace(/\ /g, '')
        const Brand = GetCardType(CardNumber)
        const payload = {
            data:{ 
                credit_card: {
                    CardNumber,
                    Holder: formValue('card_name'),
                    ExpirationDate: formValue('card_expires'),
                    SecurityCode: formValue('card_cvc'),
                    Brand
                },
            }
        }
        const result = await UpdatePlanCard(payload)
        setLoading(false)

        if(result && !exposeStrapiError(result)){
            toast.success('Cartão de cédito atualizado com sucesso') ;
            history.goBack()
        }
    }

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>                        
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Alertar Cartão de crédito</PageSubtitle> 
                            <FormSpacer small /> 


                            <Input outline placeholder="Nome (como está escrito no cartão)" value={formValue('card_name')} onChange={e => changeForm(e.target.value, 'card_name')}  />
                            <FormSpacer small />
                            <FormWrap>
                                <Input outline mask={'9999 9999 9999 9999'} placeholder="Número do Cartão" value={formValue('card_number')} onChange={e => changeForm(e.target.value, 'card_number')}  />
                            </FormWrap>
                            <FormSpacer small />  
                            <FormWrap>
                                <Input outline mask={'99/9999'} placeholder="Validade (MM/AAAA)" value={formValue('card_expires')} onChange={e => changeForm(e.target.value, 'card_expires')}  />
                                <Input outline mask={'999'} placeholder="Cod. Segurança" value={formValue('card_cvc')} onChange={e => changeForm(e.target.value, 'card_cvc')}  />
                            </FormWrap>
                            {/* <FormSpacer small />  */}
                            {/* <Input outline placeholder="CPF ou CNPJ do Titular" value={formValue('card_document_user')} onChange={e => changeForm(e.target.value, 'card_document_user')}  /> */}
 
                            <PageActions>
                                <Button primary loading={loading} onClick={save}>Salvar</Button>
                                <Button primary outline onClick={() => history.goBack()}>Cancelar</Button>
                            </PageActions> 
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}