import React from "react"; 
 

import ContainerLandpage from "containers/Landpage";

import Topo from 'components/Landpage/Topo'; 
import Banner from "components/Landpage/Banner";
import Top10 from 'components/Landpage/Top10';
import PqAssinar from 'components/Landpage/PqAssinar';
import Apoiadores from 'components/Landpage/Apoiadores';
import ComoFuncionaJK from "components/Landpage/ComoFuncionaJK";
import Planos from "components/Landpage/Planos";
import OnGestantes from "components/Landpage/OnGestantes";
import OnOriginais from "components/Landpage/OnOriginais";
import OnFavoDownload from "components/Landpage/OnFavoDownload";
import Depoimentos from "components/Landpage/Depoimentos";

import RedesSociais from "components/Landpage/RedesSociais";
import FaleConosco from "components/Landpage/FaleConosco";
import OnRodape from "components/Landpage/OnRodape";

export default function Landpage(){ 
 
    return ( 
        <ContainerLandpage> 

                <Topo /> 
                {/* <Banner /> */}
                <Top10 />
                <PqAssinar />
                <Apoiadores />  
                <ComoFuncionaJK />
                <Planos />
                {/* <OnGestantes /> */}
                {/* <OnOriginais /> */}
                {/* <OnFavoDownload /> */}
                <Depoimentos />
                
                <RedesSociais />
                <FaleConosco />
                <OnRodape />
 
        </ContainerLandpage>
    );
}