import { GET, POST, PUT, DELETE } from './api'


export const ReadHome = async () => {
    return await GET(`/home?populate[0]=featured.featured_show.category&populate[1]=featured.featured_banner&populate[2]=icon`);
} 

export const ReadHomeTabs = async () => {
    return await GET(`/tabs?populate[0]=icon`);
} 
export const ReadTabPlaylist = async () => {
// faglimar_esse era o antigo--    return await GET(`/collections?populate[0]=stream_shows.thumbnail&populate[1]=icon`);
   return await GET(`/collections?filters[tab]=6&populate[0]=stream_shows.thumbnail&populate[1]=icon`);
} 

export const ReadTabCatalog = async (tabId) => {
   return await GET(`/collections?filters[tab]=${ tabId }&populate[0]=stream_shows.thumbnail&populate[1]=icon`);
// estava testando o sort_by  return await GET(`/collections?filters[tab]=${ tabId }&populate[0]=stream_shows.thumbnail&populate[1]=icon&order[collections]=-asc`);
} 
    
    

export const ReadCatalogDetails = async (catalogId) => {
    return await GET(`/collections/${ catalogId }?&populate[0]=stream_shows.thumbnail&populate[1]=icon`);
} 

export const ReadCategories = async () => {
    return await GET(`/categories`);
} 

// Faglimar- aqui eu aumentei a quantidade de videos que aparece na pesquisa = 50
export const ReadSearch = async (searchExpression) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=50&[populate][0]=thumbnail&where[name][$containsi]=${ searchExpression }`);
} 

export const ReadSearchByTheme = async (themeId) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=50&[populate][0]=thumbnail&where[themes]=${ themeId }`);
} 

export const ReadSearchByCharacter = async (charId) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=50&[populate][0]=thumbnail&where[characters]=${ charId }`);
} 

export const ReadShowDetails = async (showId) => {
    return await GET(`/stream-shows/${ showId }?[populate][0]=thumbnail&[populate][1]=wide_picture&[populate][2]=related_shows.thumbnail&[populate][3]=stream_master_file&[populate][4]=mobile_download_mp4&[populate][5]=next_stream`, true);
} 

export const ReadPreviewsShow = async (showId) => {
    return await GET(`/stream-shows?where[next_stream]=${ showId }&limit=1`, true);
}


export const ReadSerchableCharacteres = async () => {
    return await GET(`/characters?limit=50`);
} 

export const ReadSerchableThemes = async () => {
    return await GET(`/themes?limit=50`);
} 