import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
 



export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;
 


export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 20px;
`;
 