import React from "react"; 

import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
  

import { DefaultUi, Player, Youtube } from "@vime/react";
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    BannerLogo,
    BannerText,
    BannerCenterActions,
    CommomCentred,
    ContainerLoginContent,
    LoginContent
} from './styled'
import Button from "components/Button";

export default function Topo(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <ContainerLoginContent>   
                <Row>
                            <Col md={{ size: 4 }}></Col> 
                            <Col md={{ size: 4 }}>

                            </Col>
                            <Col md={{ size: 4 }}>
                                <LoginContent>
                                    <Button accent onClick={() => navigate('login')}>Entrar</Button>
                                </LoginContent>
                            </Col>
                </Row>
            </ContainerLoginContent>
            <CommomContainer>  
                <Container> 
                    
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <BannerLogo />
                                <BannerText>Vídeos Infantis que Ensinam</BannerText>
                                <CommomTitle>A Maior Plataforma de Vídeos Cristãos para Crianças</CommomTitle>
                                <CommomText>Auxiliando os Papais e as Mamães na Educação dos Pequeninos!</CommomText>
                                <BannerCenterActions>
                                    <Button primary onClick={() => navigate('planos')}>Escolher Plano</Button>
                                </BannerCenterActions>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}

//<Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/>
// na linha 32 <Player autoplay={true}>