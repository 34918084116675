import React from "react"; 
 

import ContainerLandpage from "containers/Landpage02";

// import Banner from "components/Landpage02/Banner";
// import OnTV01 from 'components/Landpage/OnTV01'; 
// import OnTV from 'components/Landpage/OnTV';
// import OnMobile from "components/Landpage/OnMobile";
// import OnMulti from "components/Landpage/OnMulti";
import OnGestantes from "components/Landpage02/OnGestantes";
// import OnOriginais from "components/Landpage/OnOriginais";
// import OnFavoDownload from "components/Landpage/OnFavoDownload";
// import OnMultiOK from "components/Landpage/OnMultiOK";
import OnPlanos from "components/Landpage02/OnPlanos";
// import OnAndroidIOS from "components/Landpage/OnAndroidIOS";
import OnRodape from "components/Landpage02/OnRodape";

export default function Landpage(){ 
 
    return ( 
        <ContainerLandpage> 

                {/* <Banner /> */}
                {/* <OnTV01 />  
                <OnTV />  
                <OnMobile />
                <OnMulti />*/}
                
                {/*<OnOriginais />
                <OnFavoDownload />
                <OnMultiOK /> */}
                <OnPlanos />
                <OnGestantes />
                {/* <OnAndroidIOS /> */}
                <OnRodape />
 
        </ContainerLandpage>
    );
}