import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText,
    AccountItem,
    AccountItemAction,
    AccountItemActionIcon,

    AccountPlanItem,
    PlanItemContent,
    PlanItemTitle,
    PlanItemText,
    PlanItemIcon

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { CheckPlan } from "services/plans";
import { parseCurrency } from "utils";

export default function DashboardAccount(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [currentPlan, setCurrentPlan] = useState(false)
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await CheckPlan(); console.log('checked plan', result);
        if(result && result.recurrency && !result?.subscription?.canceled){
            setCurrentPlan(result)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer />
                            <PageTitle>Minha Conta</PageTitle>
                            <PageSubtitle>Detalhes da conta</PageSubtitle>

                            <AccountItem onClick={() => navigate('dashboard/account/confirm-email')}>{ user?.email }</AccountItem>
                            <AccountItem onClick={() => navigate('dashboard/account/create-password')}>
                                **********
                                <AccountItemAction>
                                    <AccountItemActionIcon icon={'edit'} />
                                </AccountItemAction>
                            </AccountItem>
                            {
                                currentPlan ? <>
                                    <PageSubtitle>Assinatura</PageSubtitle> 
                                    <AccountPlanItem onClick={() => navigate('dashboard/account/plan')}>
                                        <PlanItemContent>
                                            <PlanItemTitle>{ currentPlan?.recurrency?.RecurrentPayment?.Interval === 'Annual' ? 'Anual' : 'Mensal' }</PlanItemTitle>
                                            <PlanItemText>
                                                <b>{ parseCurrency(currentPlan?.recurrency?.RecurrentPayment?.Amount / 100) }</b>
                                            </PlanItemText>
                                        </PlanItemContent>
                                        <PlanItemIcon />
                                    </AccountPlanItem>
                                </> : null
                            }
                            
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}