import styled from 'styled-components'  
 


export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-top: 20px;           
`; 
export const ContentCheck = styled.div.attrs({ 
})`       
    display: flex;
    cursor: pointer;
`;
export const CheckItem = styled.div.attrs({ 
})`       
    margin-top: 23px;
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    border-radius: 4px;

    transition: all .3s ease;

    ${
        props => props.checked ? `
            background: var(--primary-color);
        ` : `
            border: 1px solid var(--primary-color);
        `
    }

    display: flex;
    align-items: center;
    justify-content: center;
`;
export const CheckItemIcon = styled.img.attrs({ 
    src: `/icons/check.svg`
})`        
    animation-name: pulseIn; 
    animation-duration:.3s; 
    animation-iteration-count: 1;

    @keyFrames pulseIn {
        0%{
            transform: scale(1);
        }
        50%{
            transform: scale(1.8);
        }
        100%{
            transform: scale(1);
        }
    }
`;