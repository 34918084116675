import styled from 'styled-components'  
 
export const FaqItem = styled.div.attrs({ 
})`
`;
export const FaqItemAsk = styled.div.attrs({ 
})`            
    font-size: 18px;
    color: var(--black-color);
`;
export const FaqItemAnswer = styled.div.attrs({ 
})`
    font-size: 14px;
    color: var(--black-color); 
    padding: 10px 0;
`;
 

export const FaqItemHeader = styled.div.attrs({ 
})`
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    
    cursor: pointer;
`;
export const FaqItemIcon = styled.img.attrs({ 
    src: '/icons/chevron-black.svg'
})`
    ${
        props => props.open ? `
            transform: rotate(180deg);
        ` : ``
    }
`;