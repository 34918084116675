import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 40px;
`;


 
export const PageSubtitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--white-color);
    margin-top: 20px; 
`;
export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--white-color);
    margin-top: 20px;     
    text-align: center;      
`;
 

export const PageActions = styled.div.attrs({ 
})`      
    display: flex;
    align-items: center;
    gap: 0 20px;
`;
  

export const PlanBanner = styled.div.attrs({ 
})`       
    height: 147px;
    border-radius: 5px;
    margin: 20px 0 0 0;

    background: url(/background/background-6.png) no-repeat center center / cover;
`; 