import styled from 'styled-components';

// Container principal para o título e as imagens
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  background: var(--primary-color);
`;

// Título responsivo e centralizado
export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 3vw;
    font-weight: bold;
    line-height: 3em;
    text-align: center;
    color: var(--white-color);
    margin: 0;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 6vw;
    max-width: 360px;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 2rem;
  }

`; 

// Container das imagens, organizadas horizontalmente em telas grandes e verticalmente em mobile
export const ImagesContainer = styled.div`          
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Permite que as imagens quebrem linha em telas menores
  gap: 20px; // Espaçamento entre as imagens
`;

// Imagem responsiva
export const Image = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 10px auto 10px;
    flex:1;
    width: 210px;
`; 

export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 18px;
    color: var(--darkprimary-color);    

    text-align: center;

`;

export const ColorLink = styled.div.attrs({ 
})`           
    color: var(--secondary-color);
    text-decoration: underline;  
`;