import React, { useContext } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText,
    PageResendAction,
    PageActions,
    BigInput,

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";

export default function DashboardAccountConfirmEmail(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const resent = () => {
        toast.success('Enviado') ;
    }

    return ( 
        <>
            <ContainerAuthenticated>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Confirme seu E-mail</PageSubtitle>
                            <PageText>
                                Precisamos verificar seu endereço de e-mail. Enviamos um e-mail para <b>{ user?.email }</b> com um código de 5 números que expiram em 15 minutos. Digite o código abaixo.
                            </PageText>
                            <BigInput placeholder={'00000'} />
                            <PageActions>
                                <Button primary onClick={() => navigate('dashboard/account/change-email')}>Continuar</Button>
                                <Button primary outline onClick={() => history.goBack()}>Cancelar</Button>
                            </PageActions>
                            <PageText>
                                Não recebeu o e-mail? Verifique sua caixa de Spam ou lixo eletrônico
                            </PageText>
                            <PageResendAction>
                                <Button secondary outline onClick={resent}>Reenviar</Button>
                            </PageResendAction>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}