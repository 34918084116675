import React, { useContext, useEffect, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    DashboardSpacer,
 
    PageSubtitle,
    PageText, 
    PageActions, 


    PlanBanner, 

 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import { ReadPremiumPlans } from "services/authentication";
import { parsePlan } from "utils/parser";
import { Load } from "ui/styled";
import { ChangePlan, CheckPlan } from "services/plans";
import { exposeStrapiError } from "utils";

export default function DashboardAccountChangePlan(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user, backgrounds } = useContext(CoreContext)
    const [loading, setLoading] = useState(null)
    const [availablePlan, setAvailablePlan] = useState(null)
    const [currentPlan, setCurrentPlan] = useState(false)

    const change = async () => {

        setLoading(true)
        const payload = {
            data:{
                plan: availablePlan.id
            }
        }
        const result = await ChangePlan(payload)
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success('Plano alterado com sucesso') ;
            history.goBack()
        }

    }


    const init = async () => {
        setLoading(true)
        
        const plan = await CheckPlan(); 
        if(plan && plan.recurrency ){
            setCurrentPlan(plan)
        }
        
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            const currentPlanId = parseInt(plan.subscription?.plan_id)
            setAvailablePlan(result.plans.map(mit => parsePlan( mit, backgrounds)).find(fnd => fnd.id !== currentPlanId)) 
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated primary>  
                <Container>
                    <Row>
                        <Col md={{ size: 4, offset:4 }}>
                            <DashboardSpacer /> 
                            <PageSubtitle>Alterar assinatura</PageSubtitle>  
                            {
                                loading ? <Load /> : <>
                                    <PlanBanner /> 
                                    <PageActions> 
                                        <Button secondary onClick={() => change()}>{ availablePlan?.action }</Button>
                                    </PageActions> 
                                    <PageText> 
                                        { availablePlan?.text }
                                    </PageText>  
                                </> 
                            }
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated> 
        </>
    );

}