import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
 



export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;
 

 
 

export const DashboardContent = styled.div.attrs({ 
})`            
    margin-left: 180px; 

    @media(max-width: 767px){
        margin-left: 100px; 
    }
    @media(max-width: 499px){
        margin-left: 20px; 
    } 
    @media(max-width: 419px){
        margin-left: 10px; 
    }
`;

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--white-color); 
`;
export const DashboardWrapper = styled.div.attrs({ 
})`             
    padding: 20px 0 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
`;



export const DashboardThemeItem = styled.div.attrs({ 
})`             
    padding: 8px 9px;
    background: var(--primary-color);

    font-size: 18px;
    font-weight: bold;
    color: var(--white-color); 

    transition: all .3s ease;
    cursor: pointer;
    &:hover{
        transform:scale(1.1)
    }
`;


export const DashboardUserItem = styled.div.attrs({ 
})`             
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background: var(--primary-color) url(${ props => props.image }) no-repeat center center / cover; 

    transition: all .3s ease;
    cursor: pointer;
    &:hover{
        transform:scale(1.1)
    }
`;