import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    background: var(--primary-color);
    padding: 0px 0;
`; 

export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 3vw;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
    color: var(--white-color);
    margin: 0;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 6vw;
    max-width: 280px;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 2rem;
  }

`;  
 

export const CommomText = styled.div.attrs({  
})`          
    font-size: 18px;
    line-height: 1em;
    color: var(--white-color);
    margin: 15px 0 0 0;

    width: 100%;
    max-width: 360px;
// Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 4vw;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 1rem;


`;  
 


export const CommomImageBanner = styled.div.attrs({  
    
})`           
    width: 100%;
    max-width: 540px;
    margin-top: 20px;
    margin-botton: 20px;
    //border-radius: 10px;

    @media(max-width: 767px){
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;  
 
export const CommomCentred = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${
        props => props.spaced ? `
            padding-top: 20px;
        ` : ``
    }
`; 