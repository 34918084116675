import React, { useState, createContext, useEffect } from 'react'
import { ReadBackground } from 'services/background'
import { AddFavorite, ReadFavorites, RemoveFavorite } from 'services/favorites'
import { ReadObject, SaveObject } from 'services/storage'
import { parseVideo } from 'utils/parser'
 
export const CoreContext = createContext({})

export const CoreState = ({ children }) => {
      
	const [ user, setUser ] = useState( ReadObject('user') ? ReadObject('user') : {})  
	const [ backgrounds, setBackgrounds ] = useState( ReadObject('backgrounds') ? ReadObject('backgrounds') : [])  
	const [ profiles, setProfiles ] = useState( ReadObject('profiles') ? ReadObject('profiles') : [])  
	const [ favorites, setFavorites ] = useState( ReadObject('favorites') ? ReadObject('favorites') : [])  
	const [ favoriting, setFavoriting ] = useState(false)  
	const [ currentCategory, setCurrentCategory ] = useState( ReadObject('currentCategory') ? ReadObject('currentCategory') : 0)  
	const [ currentProfile, setCurrentProfile ] = useState( ReadObject('currentProfile') ? ReadObject('currentProfile') : {} )  

	const fetchBackgrounds = async () => {
		const promises = []
		const availableBacks = [
			'walkthrough.image',
			'login',
			'montly_plan',
			'yearly_plan',
			'checkout',
			'success_purchase',
			'failed_purchase',
			'creating_profile',
			'choose_profile',
			'success_purchase_header',
			'failed_purchase_header',
			'parent_access_header',
		]
		availableBacks.map(item => promises.push(loadBackground(item)))
		const backs = await Promise.all(promises) 
		setBackgrounds(backs)

	}

	const loadBackground = async (locale) => {
		const fixedLocale = locale?.indexOf('.') !== -1 ? locale?.split('.')?.[0] : locale
		const result = await ReadBackground(locale)  
		if(result?.data?.attributes?.[fixedLocale]?.length){
			return { locale, steps: result?.data?.attributes?.[fixedLocale]?.map( item => ({ id: item?.image?.data?.id, ...item?.image?.data?.attributes }) ) }
		}
		return { locale, ...result?.data?.attributes?.[fixedLocale]?.data?.attributes, id:result?.data?.attributes?.[fixedLocale]?.data?.id }
	}

	const isFavorite = item => {
		return favorites.map(mit => mit.id).includes(parseInt(item.id))
	}

	const toggleFavorite = async item => {
		setFavoriting(true)
		if(isFavorite(item)){
			const fav = favorites.find(mit => mit.id == item.id)
			await RemoveFavorite(fav.favorite_id, currentProfile?.id)
		}else{
			await AddFavorite({ data: { stream_show: item.id } }, currentProfile?.id)
		}
		await reloadFavorites()
		setFavoriting(false)
	}

	const reloadFavorites = async () => {
		const result = await ReadFavorites(currentProfile?.id)
		if(result?.data?.length){ setFavorites(result?.data?.map(mit => ({ favorite_id: mit?.id, ...parseVideo(mit?.stream_show) }) ) ) ;}else{ setFavorites([]) ;}
	}


	const contextValue = {  
		user, setUser,
		profiles, setProfiles,
		currentProfile, setCurrentProfile,
		currentCategory, setCurrentCategory,
		backgrounds, 
		favorites, isFavorite, 
		favoriting, toggleFavorite, reloadFavorites
	}

	// to persist state when app reload  
    useEffect(() => { SaveObject('user', user) ;}, [user]) 
    useEffect(() => { SaveObject('backgrounds', backgrounds) ;}, [backgrounds]) 
    useEffect(() => { SaveObject('profiles', profiles) ;}, [profiles]) 
    useEffect(() => { SaveObject('favorites', favorites) ;}, [favorites]) 
    useEffect(() => { SaveObject('currentProfile', currentProfile) ;}, [currentProfile]) 
    useEffect(() => { SaveObject('currentCategory', currentCategory) ;}, [currentCategory]) 

	useEffect(() => { fetchBackgrounds(); reloadFavorites() ;}, [])

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
