import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'


export const FormContent = styled.div.attrs({ 
})`           
    max-width: 325px;
    margin: 0 auto;
    ${
        props => props.big ? `
            max-width: 425px;
        ` : ``
    } 
`;

 
export const WhiteCard = styled.div.attrs({ 
})`             
    margin-top: 199px;
    padding: 0px 0px 28px;
    background: var(--white-color);
    border-radius: 10px;

    margin-bottom: 40px;
`;
export const CardBanner = styled.div.attrs({ 
})`             
    height: 132px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: url(${ props => props.image }) no-repeat center center / cover;
    
    margin-bottom: 28px;
`;
export const CardTitle = styled.div.attrs({ 
})`         
    color: var(--primary-color);
    font-size: 30px; 
    font-weight: bold;     
    text-align: center;

    margin-bottom: 10px;
`;
export const CardText = styled.div.attrs({ 
})`           
    color: var(--black-color);
    font-size: 18px;     
    text-align: center;

    max-width: 200px;
    ${
        props => props.big ? `
            max-width: 310px;
        ` : ``
    }
    margin: 0 auto;
`;