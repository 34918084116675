import React from 'react';
import { Container, CommomTitle, ImagesContainer, Image, CommomCentred, BannerCenterActions, BannerTitle, ColorLink  } from './styled';

import Button from "components/Button";
import { useHistory } from 'react-router-dom';

// Caminhos das imagens (substitua com os caminhos corretos)
const image1 = '/images/Joinkids_500_videos.png';
const image2 = '/images/Joinkids_TV_Outros.png';
const image3 = '/images/Joinkids_sempublicidade.png';
const image4 = '/images/Joinkids_atividades.png';


const ResponsiveImagesSection = () => {

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
  return (
    <Container>
      {/* Título */}
      <CommomTitle>Por que assinar a JoinKids?</CommomTitle>

      {/* Container das Imagens */}
      <ImagesContainer>
        <Image src={image1} alt="Imagem 1" />
        <Image src={image2} alt="Imagem 2" />
        <Image src={image3} alt="Imagem 3" />
        <Image src={image4} alt="Imagem 4" />
      </ImagesContainer>

      <BannerCenterActions>
        <Button green onClick={() => navigate('planos')}>Assinar Agora</Button>
        <BannerTitle><a href="https://wa.me/5592982168732?text=Eu%20quero%20saber%20mais!"><ColorLink><Button secondary>Saiba mais</Button></ColorLink></a></BannerTitle>
      </BannerCenterActions>

    </Container>
  );
};

export default ResponsiveImagesSection;

//<Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/>
// na linha 32 <Player autoplay={true}>