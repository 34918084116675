import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0;
  
  align-items: center;
  padding: 10px;
  //padding: 0px 0;
  background: var(--primary-color);
`;

export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 3vw;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
    color: var(--white-color);
    
    padding: 20px;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 6vw;
    max-width: 280px;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 2rem;
  }

`;  
 

export const CommomText = styled.div.attrs({  
})`          
    font-size: 1.5vw; // Responsivo baseado na largura da viewport
    line-height: 1.5em;
    text-align: center;
    color: var(--dark-primary-color);
    margin: 15px 0 0 0;

    
    max-width: 100%;

// Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 4vw;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 1rem;
  }

`; 

export const CommomCentred = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    ${
        props => props.spaced ? `
            padding-top: 20px;
        ` : ``
    }
`; 


export const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  position: absolute;
  bottom: 20px;
`;

export const NavText = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

export const ImageContainer = styled.div`
  display: 380px;  
  border-radius: 8px;
  overflow: hidden;
  
  padding: 10px;
`;

export const StyledImage = styled.img`
  width: auto;
  height: auto;
  border-radius: 8px;
`;
