import React, { useContext, useEffect, useState } from "react";  

import {   
    BannerCarousel,

    BannerContent,
    BannerCoverShadow,
    BannerItem,
    BannerTitle,
    BannerCategoryContent,
    BannerCategoryIcon,
    BannerCategoryText,
    BannerText,
    ButtonIcon,
    BannerButtonsContent,

    StepIndicator,
    StepItem,
 

} from "./styled";
 
import { Container, Row, Col } from "reactstrap";
import Button from "components/Button"; 
import { useHistory } from "react-router-dom";
import { parseBanner } from "utils/parser";
import { CoreContext } from "context/CoreContext";

export default function CarouselBanner({ banners }){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const [ activePage, setActivePage ] = useState(0)
    const { toggleFavorite, isFavorite, favoriting } = useContext(CoreContext)

    const videos = banners?.length ? 
        banners.map(parseBanner)
    : [] 
    
    const handleSlider = () => {

        const slider = document.querySelector('.sllider');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            document.body.style.cursor = 'grab';

            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
            document.body.style.cursor = 'auto';
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
            document.body.style.cursor = 'auto';
        });
        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            // console.log(walk);
        });


        slider.addEventListener('scroll', (event) => {
            const pageWidth = window.innerWidth
            const page = parseInt( event.target.scrollLeft / (pageWidth) )
            // console.log(page, event.target.scrollLeft, pageWidth)
            if(page != activePage){ setActivePage(page) ;}
        });
    }

    useEffect(() => {
        handleSlider()
    }, [])

    return ( 
        <> 
        <BannerCarousel className="sllider">
            {
                videos.map((item, key) => 
                    <BannerContent key={key} image={item.image}>
                        <BannerCoverShadow>
                            <Container>
                                <Row>
                                    <Col>
                                        <BannerItem> 
                                            <BannerTitle>{ item.title }</BannerTitle>
                                            <BannerCategoryContent>
                                                <BannerCategoryIcon color={item.category.color} />
                                                <BannerCategoryText>{ item.category.title }</BannerCategoryText>
                                            </BannerCategoryContent>
                                            <BannerText>
                                                { item.description }
                                            </BannerText>  
                                            <BannerButtonsContent>
                                                <Button secondary onClick={() => navigate(`dashboard/player/${ item.id }/running`)}>
                                                    <ButtonIcon icon={'play'} />
                                                    Assistir
                                                </Button>
                                                <Button outline white onClick={() => toggleFavorite(item) } loading={favoriting} >
                                                    <ButtonIcon icon={'like'} />
                                                    { isFavorite(item) ? 'Desfavoritar' : 'Favoritar' }
                                                </Button>
                                            </BannerButtonsContent>
                                        </BannerItem>
                                    </Col>
                                </Row>
                            </Container>
                        </BannerCoverShadow>
                    </BannerContent> 
                )
            } 
        </BannerCarousel>
            
            <StepIndicator>
                {
                    videos.map((item, key) => 
                        <StepItem key={key} active={activePage === key} />
                    )
                } 
            </StepIndicator> 
        </>
    );
}