import React from 'react';
import { Container, Title, SocialIconsContainer, SocialIconWrapper, SocialIcon, SocialText } from './styled';

// Caminhos dos ícones das redes sociais (substitua com os caminhos corretos)
const instagramIcon = '/images/Joinkids_logo_instagram.png';
const facebookIcon = '/images/Joinkids_logo_facebook.png';
const tiktokIcon = '/images/Joinkids_logo_tiktok.png';
const linkedinIcon = '/images/Joinkids_logo_linkedin.png';
const youtubeIcon = '/images/Joinkids_logo_youtube.png';

const SocialMediaSection = () => {
  return (
    <Container>
      {/* Título */}
      <Title>Siga-nos em nossas Redes Sociais</Title>

      {/* Ícones das Redes Sociais */}
      <SocialIconsContainer>
        <SocialIconWrapper>
          <SocialIcon href="https://www.instagram.com/joinkidsbrasil/" target="_blank">
            <img src={instagramIcon} alt="Instagram" />
          </SocialIcon>
          <SocialText>Instagram</SocialText>
        </SocialIconWrapper>

        <SocialIconWrapper>
          <SocialIcon href="https://www.facebook.com/joinkidsbrasil" target="_blank">
            <img src={facebookIcon} alt="Facebook" />
          </SocialIcon>
          <SocialText>Facebook</SocialText>
        </SocialIconWrapper>

        <SocialIconWrapper>
          <SocialIcon href="https://www.tiktok.com/@joinkidsbrasil" target="_blank">
            <img src={tiktokIcon} alt="TikTok" />
          </SocialIcon>
          <SocialText>TikTok</SocialText>
        </SocialIconWrapper>

        <SocialIconWrapper>
          <SocialIcon href="https://www.linkedin.com/company/joinkids/" target="_blank">
            <img src={linkedinIcon} alt="LinkedIn" />
          </SocialIcon>
          <SocialText>LinkedIn</SocialText>
        </SocialIconWrapper>

        <SocialIconWrapper>
          <SocialIcon href="https://www.youtube.com/@JoinKidsBrasil" target="_blank">
            <img src={youtubeIcon} alt="Youtube" />
          </SocialIcon>
          <SocialText>LinkedIn</SocialText>
        </SocialIconWrapper>

      </SocialIconsContainer>
    </Container>
  );
};

export default SocialMediaSection;