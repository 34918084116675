export const availableCatagories = [
    { id:1, title:'Meus Favoritos', icon:'heart', image: '/images/users/male-1.png' },
    { id:2, title:'As 20 Músicas + Vistas', icon:'sound', image: '/images/users/male-2.png' },
    { id:3, title:'Cante e Dance', icon:'dance', image: '/images/users/female-3.png' },
]

export const availableVideos = [
    { id:1, title:'Jesus nosso salvador', cover: '/videos/video-1.png', category_id:1 },
    { id:2, title:'Louvem com Jesus', cover: '/videos/video-2.png', category_id:1 },
    { id:3, title:'O Menino Jesus - Hoje é seu aniversário', cover: '/videos/video-3.png', category_id:1 },
    { id:4, title:'Noite Feliz', cover: '/videos/video-4.png', category_id:1 },
    { id:5, title:'Samuel', cover: '/videos/video-5.png', category_id:1 },
    { id:6, title:'Minha Pequena Luz', cover: '/videos/video-6.png', category_id:1 },
    { id:7, title:'Eu preciso de você', cover: '/videos/video-7.png', category_id:1 },
    { id:8, title:'Por que é importante ler a bíblia?', cover: '/videos/video-8.png', category_id:1 },
    { id:9, title:'Desenhos bíblicos', cover: '/videos/video-9.png', category_id:1 },
    { id:10, title:'Partiu ler a Bíblia', cover: '/videos/video-10.png', category_id:1 },
    { id:11, title:'Bíblia para crianças', cover: '/videos/video-11.png', category_id:1 },
    { id:12, title:'Jesus nosso salvador', cover: '/videos/video-12.png', category_id:1 }, 

    { id:20, title:'Por que é importante ler a bíblia?', cover: '/videos/video-8.png', category_id:2 }, 
    { id:19, title:'Eu preciso de você', cover: '/videos/video-7.png', category_id:2 },
    { id:18, title:'Minha Pequena Luz', cover: '/videos/video-6.png', category_id:2 },
    { id:17, title:'Samuel', cover: '/videos/video-5.png', category_id:2 },
    { id:16, title:'Noite Feliz', cover: '/videos/video-4.png', category_id:2 },
    // { id:15, title:'O Menino Jesus - Hoje é seu aniversário', scover: '/videos/video-3.png', category_id:2 },
    // { id:14, title:'Louvem com Jesus', cover: '/videos/video-2.png', category_id:2 },
    { id:13, title:'Jesus nosso salvador', cover: '/videos/video-1.png', category_id:2 },

    { id:27, title:'Eu preciso de você', cover: '/videos/video-7.png', category_id:3 },
    { id:25, title:'Samuel', cover: '/videos/video-5.png', category_id:3 },
    { id:23, title:'O Menino Jesus - Hoje é seu aniversário', cover: '/videos/video-3.png', category_id:3 },
    { id:21, title:'Jesus nosso salvador', cover: '/videos/video-1.png', category_id:3 },
    { id:22, title:'Louvem com Jesus', cover: '/videos/video-2.png', category_id:3 },
    { id:24, title:'Noite Feliz', cover: '/videos/video-4.png', category_id:3 },
    // { id:26, title:'Minha Pequena Luz', image: '/videos/video-6.png', category_id:3 },
]

export const moreOptions = [
    { title:'Músicas', slug:'music' },
    { title:'Histórias', slug:'history' },
    { title:'Jesus', slug:'jesus' },
    { title:'Meditações', slug:'meditation' },
    { title:'Versículos', slug:'versiclos' },
    { title:'Bíblia', slug:'bible' },
]
