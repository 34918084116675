import styled from 'styled-components'  

 

 




export const VideoNavigationContent = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    padding: 40px;
    flex:1;
`;
export const VideoNavAction = styled.div.attrs({ 
})`
    width: 52px;
    height: 52px;
    border-radius: 26px;
    ${
        props => props.active ? `
            background: var(--primary-color);
        ` : `
            background: var(--secondary-color);
        `
    }

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;
export const VideoNavActionIcon = styled.img.attrs(props => ({ 
    src:`/icons/${ props.icon }.svg`,
}))`
`;
export const VideoNavProgress = styled.div.attrs({ 
})`
    height: 4px;
    background: var(--secondary-color);
    border-radius: 2px;
    flex:1;
    margin: 0 25px;
    display: flex;
`;
export const VideoNavProgressIn = styled.div.attrs({ 
})`
    height: 4px;
    background: var(--white-color);
    border-radius: 2px;
    width: ${ props => props.progress };
`;
export const VideoNavProgressDecoration = styled.div.attrs({ 
})`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background: var(--secondary-color);
    position: relative;
    margin: -12px -2px;

    display: flex;
    justify-content: center;
`;
export const VideoNavProgressDecorationText = styled.div.attrs({ 
})`
    position: absolute;
    top: 35px;
    font-size: 18px;  
    color: var(--white-color);
`;


 