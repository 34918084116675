import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 20px;
`;



export const PageTitle = styled.div.attrs({ 
})`              
    font-size: 30px;
    font-weight: bold;
    color: var(--black-color);
    margin-bottom: 40px;
    
    text-align: center;
`;
export const PageSubtitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
    margin-bottom: 20px; 
`;
export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-bottom: 20px;           
`;  
export const PageResendAction = styled.div.attrs({ 
})`      
    display: flex;
`;






export const AccountItem = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-bottom: 20px;  
    min-height: 53px;
    padding: 8px 16px;
    border: .5px solid var(--black-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    cursor: pointer;
`;


export const AccountItemAction = styled.div.attrs({ 
})`     
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: var(--secondary-color);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    :hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;

export const AccountItemActionIcon = styled.img.attrs(props => ({ 
    src:`/icons/${props.icon}.svg`
}))`     
`;







export const AccountPlanItem = styled.div.attrs({ 
})`      
    padding: 18px 14px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;

    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;
export const PlanItemContent = styled.div.attrs({ 
})`      
    flex:1;
`;
export const PlanItemTitle = styled.div.attrs({ 
})`      
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
`;
export const PlanItemText = styled.div.attrs({ 
})`      
    font-size: 14px;
    color: var(--black-color);
    b{
        font-size: 18px;
        font-weight: bold;
    }
`;
export const PlanItemIcon = styled.img.attrs({ 
    src:'/icons/chevron-secondary.svg'
})`      
`;