import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'reactstrap';
import { Container, NavButtons, NavText, ImageContainer, StyledImage } from './styled';
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'


// Importe as imagens usando caminhos relativos à raiz do projeto
const image1 = '/images/Joinkids_desenhos_TOP10-SMI-02.png';
const image2 = '/images/Joinkids_desenhos_Musicanto_Aquecer_TH.png';
const image3 = '/images/Joinkids_desenhos_Casinha_sobre_a_Rocha.png';
const image4 = '/images/Joinkids_desenhos_2_PEQ_ATOS_SIMPLES_ASSIM_DEUS_ESTA_AQUI_TH.png';
const image5 = '/images/Joinkids_desenhos_Esconde_Esconde.png';
const image6 = '/images/Joinkids_desenhos_Colorindo_a_Mae_de_Jesus.png';
const image7 = '/images/Joinkids_desenhos_Restaurados.png';
const image8 = '/images/Joinkids_desenhos_Tempo de Louvar.png';
const image9 = '/images/Joinkids_desenhos_07_CH_Smi_aDistracaoPiria.png';
const image10 = '/images/Joinkids_desenhos_1_EP_Dorcas_a_Generosa_Avatar.png';
const image11 = '/images/Joinkids_desenhos_1_Quem_Criou_TH.png';
const image12 = '/images/Joinkids_desenhos_Dia_de_Festa_Com_Jesus.png';
const image13 = '/images/Joinkids_desenhos_VdJ_Episodio_1_TH.png';
//const image14 = '/images/Joinkids_apoiador_tia_ceceu.png';
const image15 = '/images/Joinkids_desenhos_Assim_oh.png';
const image16 = '/images/Joinkids_desenhos_Abertura.png';
const image17 = '/images/Joinkids_desenhos_A_Bondosa.png';
//const image18 = '/images/Joinkids_apoiador_rebecanemer.png';
const image19 = '/images/Joinkids_desenhos_Achados_e_perdidos.png';
//const image20 = '/images/Joinkids_apoiador_playcomopai.png';
const image21 = '/images/Joinkids_desenhos_1_Historia_Moises_News.png';
//const image22 = '/images/Joinkids_apoiador_sheepelitos.png';
const image23 = '/images/Joinkids_desenhos_4_PAO_AZIMO_BIBLIA_NA_COZINHA_PASCOA_TH.png';
//const image24 = '/images/Joinkids_apoiador_3palavrinhas.png';
const image25 = '/images/Joinkids_desenhos_Fingerprint_Ministry_Jonas_e_o_Grande_Peixe.png';
//const image26 = '/images/Joinkids_apoiador_criancas_diante_do_trono.png';
const image27 = '/images/Joinkids_desenhos_Fazendinha_da_Vovo.png';
//const image28 = '/images/Joinkids_apoiador_smilinguido.png';
const image29 = '/images/Joinkids_desenhos_Rabisco_e_a_Verdadeir_Pascoa_HLS_TH.png';
//const image30 = '/images/Joinkids_apoiador_turma_do_cristaozinho.png';


const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image15, image16, image17, image19, image21, image23, image25, image27, image29];
const Appok = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Função para avançar automaticamente
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Mudar a cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  // Verifica se a tela é grande (acima de 1024px)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  // Verifica se a tela é pequena (abaixo de 1024px)
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  // Define quantas imagens mostrar de acordo com o tamanho da tela
  const slidesToShow = isDesktopOrLaptop ? 4 : 1.5;
  const slideWidthPercentage = isDesktopOrLaptop ? 25 : 66.67; // 25% para 4 imagens e 66.67% para 1.5 imagens

  return (
    <Container>
         {/*<Row>*/}
                       <Col md={{size:6}}>
                            <CommomCentred spaced>
                                <CommomTitle>Desenhos que seus filhos vão amar</CommomTitle>
                                {/* <CommomText>A JoinKids agradece o apoio dessas empresas parceiras: Smilinguido, Turma do Cristaozinho, Minha Vida é uma Viagem, Tia Ceceu, 3 Palavrinhas, Crianças Diante do Trono, Rebeca Nemer Kids, Fingerprint Ministery, Sheepelitos, Bíblia na Cozinha e Play com o Pai</CommomText> */}
                                
                            </CommomCentred>
                        </Col>
        {/*</Row> */}
        {/* <h1>Bem-vindo ao Nosso Carrossel</h1>
        <p>Deslize para ver nossas imagens, com exibição automática de novas imagens a cada 5 segundos.</p>
 */}
      <Carousel
        selectedItem={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        showThumbs={false}
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        emulateTouch={true}
        showStatus={false}
        swipeable={true}
        centerMode={true}
        centerSlidePercentage={slideWidthPercentage} // // Ajusta conforme o tamanho da tela
        showIndicators={true}
      >
        {images.map((src, index) => (
          <ImageContainer key={index}>
            <StyledImage src={src} alt={`Image ${index + 1}`} />
          </ImageContainer>
        ))}
      </Carousel>
      {/* <NavButtons>
        <button onClick={() => setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 4 : prevIndex - 1))}>
          <NavText>{'<'}</NavText>
        </button>
        <button onClick={() => setActiveIndex((prevIndex) => (prevIndex === images.length - 4 ? 0 : prevIndex + 1))}>
          <NavText>{'>'}</NavText>
        </button>
      </NavButtons> */}
                
    </Container>
  );
};

export default Appok;
