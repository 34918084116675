import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import DashboardRowVideos from "components/Dashboard/RowVideos";
import DashboardMenu from "components/Dashboard/Menu";
import CarouselBanner from "components/Dashboard/CarouselBanner";
import { availableCatagories, availableVideos, moreOptions } from "utils/mock";
import { CoreContext } from "context/CoreContext";
import { useParams } from "react-router-dom";
import { ReadTabCatalog } from "services/catalog";
import { CenterLoad, Load } from "ui/styled";
import { parseCollection } from "utils/parser";

export default function DashboardCategory(){   

    const { slug } = useParams()
    const currentCategory = moreOptions.find(fnd => fnd.slug === slug ) || { title:'' }



    const [loading, setLoading] = useState(false)
    const [categoryCollections, setCategoryCollections] = useState([])

    const initCategory = async () => {
        setLoading(true)
        const result = await ReadTabCatalog(slug)
        if(result?.data?.length){
            setCategoryCollections(result?.data?.map(parseCollection)) 
        }else{
            setCategoryCollections([])
        }
        setLoading(false) 
    }

    useEffect(() => { 
        if(slug){
            initCategory()
        }
    }, [slug]) 

    return ( 
        <>
            <ContainerAuthenticated primary>  
                <DashboardSpacer />
                { 
                    loading ? 
                        <CenterLoad>
                                <Load white marginBottom={20} /> 
                        </CenterLoad>
                    : null 
                }
                {
                    categoryCollections.map((cat, kcat) => 
                        <DashboardRowVideos key={kcat} wrapper {...cat} /> 
                    )
                } 
            </ContainerAuthenticated> 
        </>
    ); 
}