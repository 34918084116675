
import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer,

    PageTitle,
    PageSubtitle,
    PageText

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";  

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { ReadAbout } from "services/authentication";
import { Load } from "ui/styled";

import {marked} from 'marked';

export default function DashboardPrivacityPolice(){   

    const { currentCategory } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [about, setAbout] = useState("")

    const init = async () => {
        setLoading(true)
        const result =  await ReadAbout()
 
        if(result?.about){

            marked.setOptions({
                gfm: true,
                tables: true,
                breaks: false,
                pedantic: false,
                sanitize: true,
                smartLists: true,
                smartypants: false
              });

            setAbout(marked.parse(result?.about))
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
                
            
                <Container>
                    <Row>
                        <Col md={{ size: 10, offset:1 }}>
                            <DashboardSpacer />
                            <PageTitle>Termos de uso e Declaração de privacidade</PageTitle> 
                            {
                               //Console.log ("about")
                               // loading ? <Load /> :
                               //<PageText dangerouslySetInnerHTML={{ __html: about }} />
                               <PageText> <b>  </b>
                               <h1 align="center"><a name="_iz7gpldapqw9"></a><strong>POL&Iacute;TICA DE PRIVACIDADE</strong></h1>

<h1 align="center"><a name="_cxvi9eu8g1oy"></a><strong>Termos de uso</strong></h1>

<p><strong>JOINKIDS ENTRETENIMENTO S.A. </strong>(&ldquo;PLATAFORMA&rdquo;)<strong>,</strong> sociedade inscrita no CNPJ sob o n&ordm; 31570972000145, com sede no endere&ccedil;o Rua Acre, n&ordm; 26, Edif&iacute;cio Man First Tower, Sala 104, Bairro Nossa Senhora das Gra&ccedil;as, CEP 69.053-130 e o USU&Aacute;RIO celebram o presente TERMO DE USO (&ldquo;TERMO&rdquo;), que define as condi&ccedil;&otilde;es pelas quais o USU&Aacute;RIO poder&aacute; acessar e utilizar as funcionalidades do sistema, disponibilizado em ambiente web com seu uso limitado &agrave;s presentes condi&ccedil;&otilde;es de licenciamento.</p>

<h5><a name="_2242eqfxnylr"></a><strong>1. Aceita&ccedil;&atilde;o do termo de uso</strong></h5>

<p>1.1. Para a realiza&ccedil;&atilde;o do cadastro, utiliza&ccedil;&atilde;o das funcionalidades da Plataforma, realiza&ccedil;&atilde;o da Assinatura e visualiza&ccedil;&atilde;o do Conte&uacute;do do Canal, &eacute; obrigat&oacute;ria a leitura, compreens&atilde;o e &ldquo;Aceite&rdquo; do presente TERMO. Ao manifestar o &ldquo;Aceite&rdquo;, o USU&Aacute;RIO declara expressamente que leu, entendeu e compreendeu todos os direitos e obriga&ccedil;&otilde;es determinados no TERMO, e ainda, concorda e se sujeita a todas as disposi&ccedil;&otilde;es do presente instrumento.</p>

<p>1.2. A utiliza&ccedil;&atilde;o da Plataforma, mesmo que parcial ou a t&iacute;tulo de teste, indica que o USU&Aacute;RIO concorda com o presente TERMO.</p>

<p>1.3. O presente TERMO poder&aacute; sofrer altera&ccedil;&otilde;es peri&oacute;dicas, seja por quest&otilde;es legais ou estrat&eacute;gicas da PLATAFORMA. O USU&Aacute;RIO desde j&aacute; concorda e reconhece que &eacute; de sua &uacute;nica e inteira responsabilidade a verifica&ccedil;&atilde;o peri&oacute;dica deste TERMO. A PLATAFORMA poder&aacute;, por mera liberalidade, informar ao USU&Aacute;RIO sobre altera&ccedil;&otilde;es significativas neste TERMO, por meio da divulga&ccedil;&atilde;o de avisos ou informativos ao USU&Aacute;RIO, enviados por meio do e-mail cadastrado pelo USU&Aacute;RIO na Plataforma.</p>

<h5><a name="_1v49mxgpno7g"></a><strong>2. Defini&ccedil;&otilde;es</strong></h5>

<p>2.1. Para os efeitos deste instrumento, os voc&aacute;bulos e express&otilde;es abaixo t&ecirc;m as seguintes defini&ccedil;&otilde;es, podendo ser utilizados no singular ou plural:</p>
<p>2.1.1. JOINKIDS: consiste em uma plataforma (&ldquo;Plataforma&rdquo;), desenvolvida e de propriedade exclusiva da JOINKIDS ENTRETENIMENTO LTDA, a qual possui os direitos de uso para realizar a distribui&ccedil;&atilde;o de v&iacute;deos de Conte&uacute;do diversificado produzidos pela PLATAFORMA, sendo um canal de informa&ccedil;&otilde;es variadas, que pode ser utilizado para diversos fins pelo USU&Aacute;RIO;</p>
<p>2.1.2. &ldquo;Canal&rdquo;: espa&ccedil;o exclusivo da PLATAFORMA, para disponibiliza&ccedil;&atilde;o do Conte&uacute;do para o USU&Aacute;RIO na Plataforma;</p>

<p>2.1.3. &ldquo;USU&Aacute;RIO(S)&rdquo;: designa a pessoa f&iacute;sica, ou jur&iacute;dica, com plena capacidade de contratar, que acessa a Plataforma e realiza o seu cadastro pessoal de modo a usufruir das funcionalidades oferecidas por esta, aderindo desta forma automaticamente ao presente TERMO;</p>

<p>2.1.4. &ldquo;Dados Financeiros&rdquo;: dados relativos ao cart&atilde;o de cr&eacute;dito, utilizado pelo USU&Aacute;RIO para realiza&ccedil;&atilde;o da Assinatura do Canal, tais como, n&uacute;mero do cart&atilde;o de cr&eacute;dito, nome impresso no cart&atilde;o, data de validade, c&oacute;digo de seguran&ccedil;a e bandeira;</p>

<p>2.1.5. &ldquo;Conte&uacute;do&rdquo;: conjunto de cria&ccedil;&otilde;es de titularidade da PLATAFORMA, e de responsabilidade exclusiva deste, podendo incluir, sem limita&ccedil;&otilde;es, v&iacute;deos, fotografias, ilustra&ccedil;&otilde;es e outras imagens, sons, anima&ccedil;&otilde;es, obras tridimensionais e outras obras que possam ser distribu&iacute;das (com ou sem convers&atilde;o de formatos) na Plataforma ao USU&Aacute;RIO, por meio do Canal;</p>

<p>2.1.6. &ldquo;Cole&ccedil;&otilde;es&rdquo;: ambiente do Canal, no qual fica alocado o Conte&uacute;do da PLATAFORMA, para acesso e realiza&ccedil;&atilde;o da Assinatura pelo USU&Aacute;RIO, separados por temas, a crit&eacute;rio da PLATAFORMA;</p>

<p>2.1.7. &ldquo;Informa&ccedil;&otilde;es Pessoais&rdquo;: ambiente da Plataforma no qual o USU&Aacute;RIO insere seus dados pessoais, solicitados pela JOINKIDS;</p>

<p>2.1.8. &ldquo;Informa&ccedil;&otilde;es Financeiras&rdquo;: dados relativos ao cart&atilde;o de cr&eacute;dito, utilizado pelo USU&Aacute;RIO para realiza&ccedil;&atilde;o da Assinatura do Canal, tais como, n&uacute;mero do cart&atilde;o de cr&eacute;dito, nome impresso no cart&atilde;o, data de validade, c&oacute;digo de seguran&ccedil;a e bandeira;</p>

<p>2.1.9. &ldquo;Assinatura&rdquo;: ato por meio do qual o USU&Aacute;RIO, adquire o direito de assistir ao Conte&uacute;do publicado em uma Cole&ccedil;&atilde;o, pelo prazo, pre&ccedil;o e condi&ccedil;&otilde;es pr&eacute;-determinadas pela PLATAFORMA em seu Canal.</p>

<h5><a name="_g96xmgm1snha"></a><strong>3. Concess&atilde;o de acesso ao Conte&uacute;do</strong></h5>

<p>3.1. A PLATAFORMA, propriet&aacute;rio dos direitos autorais do Conte&uacute;do, neste ato e pela melhor forma de direito, autoriza o USU&Aacute;RIO, mediante a realiza&ccedil;&atilde;o da Assinatura, a acessar o Conte&uacute;do publicado por ele no Canal, por meio da Plataforma, pelo prazo e condi&ccedil;&otilde;es determinadas pela PLATAFORMA no momento da realiza&ccedil;&atilde;o da Assinatura pelo USU&Aacute;RIO.</p>

<p>3.2. O USU&Aacute;RIO declara-se integralmente respons&aacute;vel pelas Informa&ccedil;&otilde;es Pessoais e Informa&ccedil;&otilde;es Financeiras inseridas na Plataforma, sendo que, compete a este, manter o seu cadastro sempre atualizado, sob pena de o USU&Aacute;RIO n&atilde;o receber todas e quaisquer informa&ccedil;&otilde;es acerca da Plataforma, em virtude de estar com seus dados desatualizados.</p>

<p>3.3. O USU&Aacute;RIO n&atilde;o est&aacute; autorizado a praticar atos que visem difamar, perseguir, praticar bullying, abusar, assediar, amea&ccedil;ar ou intimidar a PLATAFORMA, em virtude de informa&ccedil;&otilde;es obtidas por meio de acesso ao Conte&uacute;do.</p>

<h5><a name="_7j0fade5hwg0"></a><strong>4. Funcionamento</strong></h5>

<p>4.1 Funcionamento geral. A Plataforma consiste em uma ferramenta para disponibiliza&ccedil;&atilde;o de Conte&uacute;do digital pela JOINKIDS, no Canal, o qual poder&aacute; ser visualizado pelo USU&Aacute;RIO, mediante a realiza&ccedil;&atilde;o da Assinatura das Cole&ccedil;&otilde;es.</p>

<p>4.2 Ao acessar o Canal, mais precisamente a ferramenta &ldquo;Cole&ccedil;&otilde;es&rdquo;, o USU&Aacute;RIO poder&aacute; visualizar (i) o Conte&uacute;do disponibilizado pela PLATAFORMA, (ii) a quantidade de v&iacute;deos dispon&iacute;veis em cada Cole&ccedil;&atilde;o, (iii) o tempo total de Cont&eacute;udo publicado na respectiva Cole&ccedil;&atilde;o e (iv) as condi&ccedil;&otilde;es estabelecidas para a realiza&ccedil;&atilde;o da Assinatura.</p>

<p>4.2.1 A PLATAFORMA, no momento de disponibiliza&ccedil;&atilde;o de cada Cole&ccedil;&atilde;o, ir&aacute; definir, a seu &uacute;nico e exclusivo crit&eacute;rio, a quantidade de v&iacute;deos disponibilizados em cada Cole&ccedil;&atilde;o, bem como o valor da Assinatura.</p>

<p>4.3 Caso queira realizar a assinatura do Canal, de forma a ter acesso ao Conte&uacute;do publicado pela PLATAFORMA, o USU&Aacute;RIO dever&aacute; efetuar o seu cadastro, por meio da ferramenta &ldquo;Cadastra-se&rdquo;, mediante o fornecimento das Informa&ccedil;&otilde;es Pessoais e Informa&ccedil;&otilde;es Financeiras, sendo que ele responder&aacute; pela veracidade e atualiza&ccedil;&atilde;o dos dados informados no cadastro.</p>

<p>4.3.1 Preenchidos os dados pessoais solicitados nos termos do item 4.3 e aceito o presente TERMO, o USU&Aacute;RIO estar&aacute; apto a realizar a Assinatura do Canal da PLATAFORMA.</p>

<h5><a name="_ybga3jprtyzr"></a><strong>5. Planos de Contrata&ccedil;&atilde;o</strong></h5>

<p>5.1 O USU&Aacute;RIO poder&aacute; realizar, a seu crit&eacute;rio, a Assinatura mensal, trimestral, semestral ou anual do Canal, sendo que os valores, bem como o prazo de dura&ccedil;&atilde;o do acesso, em cada uma das modalidades, estar&atilde;o discriminados no Canal e acess&iacute;vel ao USU&Aacute;RIO no momento da Assinatura.</p>

<p>5.1.1 Renova&ccedil;&atilde;o Autom&aacute;tica da vig&ecirc;ncia do Plano contratado. O USU&Aacute;RIO desde j&aacute; se declara ciente de que a assinatura do plano contratado em qualquer das modalidades (mensal, trimestral, semestral ou anual) ser&aacute; renovada automaticamente quando do t&eacute;rmino da vig&ecirc;ncia do prazo do referido plano, sendo que, a nova cobran&ccedil;a relativa ao valor da Assinatura ser&aacute; realizada automaticamente em seu cart&atilde;o de cr&eacute;dito at&eacute; o t&eacute;rmino da vig&ecirc;ncia do plano renovado. Caso o USU&Aacute;RIO n&atilde;o tenha interesse na renova&ccedil;&atilde;o do plano, dever&aacute; cancel&aacute;-lo na Plataforma, antes da data de renova&ccedil;&atilde;o deste.</p>

<p>5.1.2 Cancelamento do Plano contratado. O USU&Aacute;RIO, desde j&aacute;, se declara ciente de que o cancelamento da assinatura do plano em qualquer das modalidades (mensal, trimestral, semestral ou anual), nos termos Lei 12.965/2014 (Marco Civil da Internet), n&atilde;o resultar&aacute; no reembolso e/ou devolu&ccedil;&atilde;o, pela PLATAFORMA, dos valores j&aacute; adimplidos pelo USU&Aacute;RIO, com exce&ccedil;&atilde;o da hip&oacute;tese descrita no item 12.2.1.</p>

<p>5.1.2.1 O USU&Aacute;RIO declara, tamb&eacute;m, estar ciente de que o cancelamento e/ou desist&ecirc;ncia dos planos mensal, trimestral, semestral e/ou anual tamb&eacute;m n&atilde;o permitem o estorno, devolu&ccedil;&atilde;o ou invalida&ccedil;&atilde;o do pagamento dos valores futuros e que integram o plano contratado. Dessa forma, em caso de cancelamento do plano contratado durante o prazo de vig&ecirc;ncia deste, a cobran&ccedil;a relativa ao valor da Assinatura continuar&aacute; a ser realizada automaticamente em seu cart&atilde;o de cr&eacute;dito at&eacute; o t&eacute;rmino da vig&ecirc;ncia do plano que houver sido cancelado pelo USU&Aacute;RIO.</p>

<p>5.2 A PLATAFORMA poder&aacute; alterar os valores de assinatura do Canal. Caso a PLATAFORMA altere o valor da assinatura somente novos USU&Aacute;RIOS ser&atilde;o afetados.</p>

<p>5.3 O pagamento relativo &agrave; Assinatura descrito no item 5.1, ser&aacute; realizado pelo USU&Aacute;RIO, na pr&oacute;pria Plataforma, via cart&atilde;o de cr&eacute;dito ou Boleto de Cobran&ccedil;a.</p>

<p>5.3.1 O USU&Aacute;RIO desde j&aacute; se declara ciente de que a PLATAFORMA n&atilde;o possui qualquer tipo de responsabilidade, caso a opera&ccedil;&atilde;o por meio do cart&atilde;o de cr&eacute;dito n&atilde;o seja conclu&iacute;da e/ou aprovada pela operadora do cart&atilde;o.</p>

<h5><a name="_zfxedlqemca0"></a><strong>6. Suporte T&eacute;cnico</strong></h5>

<p>6.1 O Suporte T&eacute;cnico limita-se ao atendimento ao USU&Aacute;RIO pela PLATAFORMA, por meio de troca de informa&ccedil;&otilde;es, por e-mail, via Plataforma, na aba &ldquo;Fale Conosco&rdquo;, n&atilde;o estando inclu&iacute;do neste servi&ccedil;o, o atendimento in loco ou via telefone.</p>

<p>6.2 O Suporte T&eacute;cnico fornecido pela PLATAFORMA n&atilde;o abrange a conex&atilde;o com a internet, rede interna e computadores/dispositivos m&oacute;veis do USU&Aacute;RIO.</p>

<h5><a name="_ukdu79kpxxnq"></a><strong>7. Melhorias/Corre&ccedil;&otilde;es/Novas Vers&otilde;es</strong></h5>

<p>7.1 A Plataforma poder&aacute; ser aperfei&ccedil;oada, conforme conveni&ecirc;ncia e necessidade, podendo inclusive ocorrer suspens&atilde;o tempor&aacute;ria dos servi&ccedil;os em virtude de aperfei&ccedil;oamento. Todas as modifica&ccedil;&otilde;es, melhorias e corre&ccedil;&otilde;es efetuadas na Plataforma, ficam incorporadas a esta e sujeitas a este TERMO.</p>

<p>7.2 Faculta-se &agrave; PLATAFORMA acolher as sugest&otilde;es de melhorias realizadas pelo USU&Aacute;RIO, n&atilde;o assistindo a ele, em nenhuma hip&oacute;tese, qualquer direito de propriedade sobre tais inser&ccedil;&otilde;es ou melhorias.</p>

<h5><a name="_afr7ay5hxd48"></a><strong>8. Pol&iacute;tica de Confidencialidade e Privacidade</strong></h5>

<p>8.1 Cada Parte tratar&aacute; de forma estritamente confidencial as informa&ccedil;&otilde;es levadas a seu conhecimento pela outra Parte e somente as utilizar&aacute; no &acirc;mbito dos servi&ccedil;os ora pactuados.</p>

<p>8.2 Entende-se por Informa&ccedil;&otilde;es Confidenciais todas as informa&ccedil;&otilde;es exclusivas de uma das Partes (Parte Reveladora) revelados &agrave; outra Parte (Parte Receptora), de modo verbal ou por escrito, que sejam indicadas como confidenciais ou que pelo bom senso devam ser consideradas confidenciais em vista da natureza das informa&ccedil;&otilde;es, especialmente em rela&ccedil;&atilde;o &agrave;s informa&ccedil;&otilde;es fornecidas pelo USU&Aacute;RIO no momento do cadastro, tais como: nome, idade, e-mail etc., que ser&atilde;o utilizadas para fins do presente TERMO.</p>

<p>8.3 Cada uma das Partes concorda em proteger o sigilo das Informa&ccedil;&otilde;es Confidenciais da outra Parte, da mesma forma como atua na prote&ccedil;&atilde;o do sigilo de suas pr&oacute;prias informa&ccedil;&otilde;es exclusivas e confidenciais do mesmo tipo.</p>

<p>8.4 O acesso &agrave;s informa&ccedil;&otilde;es coletadas &eacute; restrito a pessoas autorizadas para esta finalidade. Quaisquer pessoas autorizadas que se utilizarem indevidamente das Informa&ccedil;&otilde;es Pessoais e Informa&ccedil;&otilde;es Financeiras do USU&Aacute;RIO, de forma a n&atilde;o respeitar a pol&iacute;tica de confidencialidade e privacidade, estar&atilde;o sujeitas &agrave;s penalidades legais cab&iacute;veis.</p>

<p>8.5 As informa&ccedil;&otilde;es do USU&Aacute;RIO ser&atilde;o fornecidas, quando solicitadas, por meio pr&oacute;prio, apenas por ordem legal ou judicial.</p>

<h5><a name="_ljswunzhqexc"></a><strong>9. Restri&ccedil;&otilde;es ao Uso da PLATAFORMA</strong></h5>

<p>9.1 O presente TERMO &eacute; regido pelas disposi&ccedil;&otilde;es da Lei 9.609/98 e Lei 9.610/98, ficando os infratores sujeitos &agrave;s penas dos crimes previstos no art. 184 do C&oacute;digo Penal, sem preju&iacute;zo da responsabilidade civil pelos danos eventualmente causados pelo uso e distribui&ccedil;&atilde;o de c&oacute;pias n&atilde;o autorizadas do Conte&uacute;do ou por qualquer outra viola&ccedil;&atilde;o aos direitos decorrentes da propriedade deste.</p>

<p>9.2 &Eacute; vedado qualquer procedimento que implique em (i) engenharia reversa, descompila&ccedil;&atilde;o, desmontagem, tradu&ccedil;&atilde;o, adapta&ccedil;&atilde;o e/ou modifica&ccedil;&atilde;o da Plataforma, ou qualquer outra conduta que possibilite o acesso ao c&oacute;digo fonte desta; (ii) copiar, ceder, sublicenciar, vender, dar em loca&ccedil;&atilde;o ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provis&oacute;ria ou permanentemente, o Conte&uacute;do do Canal; e (iii) disponibilizar acesso a terceiros mediante o fornecimento de senha e usu&aacute;rio cadastrados.</p>

<p>9.3 O Conte&uacute;do &eacute; protegido por leis de direitos autorais e de propriedade intelectual. O direito de uso do Conte&uacute;do &eacute; licenciado ao USU&Aacute;RIO &uacute;nica e exclusivamente para visualiza&ccedil;&atilde;o no Canal, n&atilde;o existindo, portanto, qualquer cess&atilde;o de direitos relativos &agrave; titularidade deste.</p>

<h5><a name="_5xvppy1g44uj"></a><strong>10. Limita&ccedil;&atilde;o de responsabilidade</strong></h5>

<p>10.1 A PLATAFORMA n&atilde;o ser&aacute; responsabilizado pelos (i) resultados produzidos pela Plataforma, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como v&iacute;rus, ou por falha de opera&ccedil;&atilde;o ou corrup&ccedil;&atilde;o de dados; (ii) qualquer defeito decorrente de culpa exclusiva do USU&Aacute;RIO; (iii) danos ou preju&iacute;zos decorrentes de decis&otilde;es administrativas, gerenciais ou comerciais tomadas com base no Conte&uacute;do; (iv) problemas na rede f&iacute;sica e l&oacute;gica do USU&Aacute;RIO; (v) toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas possam ser atribu&iacute;das, de forma direta ou indireta, ao fornecimento, utiliza&ccedil;&atilde;o ou desempenho da Plataforma; e (vi) eventos definidos na legisla&ccedil;&atilde;o civil como caso fortuito ou for&ccedil;a maior.</p>

<h5><a name="_aesqzdr4n9ed"></a><strong>11. Vig&ecirc;ncia</strong></h5>

<p>11.1 O presente TERMO tem vig&ecirc;ncia por prazo indeterminado, a contar do momento da realiza&ccedil;&atilde;o da Assinatura do Canal e consequente aceite deste TERMO, nos termos do item 5.1.</p>

<h5><a name="_imwdyuuwt7f3"></a><strong>12. Rescis&atilde;o</strong></h5>

<p>12.1 Pela PLATAFORMA:</p>

<p>12.1.1 O presente TERMO poder&aacute; ser rescindido antecipadamente pela PLATAFORMA, a qualquer momento, mediante comunica&ccedil;&atilde;o eletr&ocirc;nica pr&eacute;via ao USU&Aacute;RIO, sem que assista ao USU&Aacute;RIO qualquer direito &agrave; reclama&ccedil;&atilde;o.</p>

<p>12.1.2 No caso descrito no item 12.1.1, o USU&Aacute;RIO ser&aacute; reembolsado integralmente do valor pago pela Assinatura, no respectivo m&ecirc;s, mediante realiza&ccedil;&atilde;o de estorno no cart&atilde;o de cr&eacute;dito, nos prazos e condi&ccedil;&otilde;es estabelecidos pela operadora do cart&atilde;o.</p>

<p>12.1.3 A realiza&ccedil;&atilde;o do referido estorno ser&aacute; de responsabilidade e obriga&ccedil;&atilde;o exclusiva da PLATAFORMA, o qual dever&aacute; realizar o procedimento por meio da PLATAFORMA, responsabilizando-se diretamente perante o USU&Aacute;RIO acerca do cumprimento desta obriga&ccedil;&atilde;o.</p>

<p>12.1.4 Para compras realizadas via Boleto, o estorno s&oacute; &eacute; completado quando os dados banc&aacute;rios do usu&aacute;rio para dep&oacute;sito (nome completo, CPF, Banco, Ag&ecirc;ncia com d&iacute;gito verificador e n&uacute;mero da conta corrente com d&iacute;gito verificador) forem informados pela JOINKIDS na Plataforma.</p>

<p>12.2 Pelo USU&Aacute;RIO:</p>

<p>12.2.1 Caso o USU&Aacute;RIO desista da Assinatura, em at&eacute; 07 (sete) dias a contar da data de confirma&ccedil;&atilde;o da realiza&ccedil;&atilde;o da Assinatura na Plataforma e desde que n&atilde;o tenha usufru&iacute;do do servi&ccedil;o contratado, este dever&aacute; entrar em contato com a PLATAFORMA e encaminhar email para o seguinte endere&ccedil;o eletr&ocirc;nico atendimento@joinkids.com.br, solicitando a desist&ecirc;ncia da Assinatura e, caso a compra tenha sido realizada no boleto, enviar tamb&eacute;m nome completo, CPF, Banco, Ag&ecirc;ncia com d&iacute;gito verificador e n&uacute;mero da conta corrente com d&iacute;gito verificador. A integralidade dos valores pagos, ser&aacute; reembolsada ao USU&Aacute;RIO, mediante realiza&ccedil;&atilde;o de estorno no cart&atilde;o de cr&eacute;dito, nos prazos e condi&ccedil;&otilde;es estabelecidos pela operadora do cart&atilde;o; ou, no caso do boleto, em dep&oacute;sito na conta corrente enviada por e-mail. A realiza&ccedil;&atilde;o do referido estorno ser&aacute; de responsabilidade e obriga&ccedil;&atilde;o exclusiva da JOINKIDS, o qual dever&aacute; realizar o procedimento por meio da PLATAFORMA, responsabilizando-se diretamente perante o USU&Aacute;RIO acerca do cumprimento desta obriga&ccedil;&atilde;o.</p>

<p>12.2.2 Caso n&atilde;o deseje renovar sua assinatura, o USU&Aacute;RIO dever&aacute; cancelar a mesma no painel de usu&aacute;rio.</p>

<p>12.3 O USU&Aacute;RIO declara-se ciente que, caso o presente TERMO seja rescindido, independentemente do motivo, este ficar&aacute; automaticamente impossibilitado de acessar o Canal e utilizar a Plataforma, bem como de visualizar o Conte&uacute;do.</p>

<h5><a name="_o6v0b27fx2f"></a><strong>13. Disposi&ccedil;&otilde;es finais</strong></h5>

<p>13.1 A toler&acirc;ncia de uma Parte com a outra quanto ao descumprimento de qualquer uma das obriga&ccedil;&otilde;es assumidas neste TERMO n&atilde;o implicar&aacute; em nova&ccedil;&atilde;o ou ren&uacute;ncia de direito. A Parte tolerante poder&aacute;, a qualquer tempo, exigir da outra Parte o fiel e cabal cumprimento deste TERMO.</p>

<p>13.2 O presente TERMO vincula as Partes e seus sucessores a qualquer t&iacute;tulo.</p>

<p>13.3 A inviabilidade da execu&ccedil;&atilde;o ou invalidade, ainda que em virtude de decis&atilde;o judicial, de alguma cl&aacute;usula, n&atilde;o afetar&aacute; a validade das demais cl&aacute;usulas deste TERMO, que permanecer&aacute; v&aacute;lido e execut&aacute;vel conforme seus termos.</p>

<p>13.4 As Partes elegem o foro da comarca da cidade de Belo Horizonte, Estado de Minas Gerais, para dirimir qualquer controv&eacute;rsia oriunda do presente TERMO, renunciando expressamente a qualquer outro, por mais privilegiado que seja.</p>

<p><strong>Declaro que li e estou ciente das condi&ccedil;&otilde;es acima especificadas.</strong></p>

<p></p>


                               
                               
                                 </PageText>
                               
                            }
                        </Col>
                    </Row>
                </Container>

        </>
    );

}