import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 40px;
`;


 
export const PageSubtitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
    margin-top: 20px; 
`;
export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-top: 20px;           
`;
 

export const PageActions = styled.div.attrs({ 
})`      
    display: flex;
    align-items: center;
    gap: 0 20px;
`;

export const BigInput = styled.input.attrs({ 
})`      
    
    font-size: 15px; 
    color: var(--black-color);
    padding: 0 0 0 20px;
    width: 100%;
    height: 65px;
    background: none;
    outline: none;
    margin-top: 20px;
    border: 1px solid var(--shadow-color);
    border-radius: 10px;  
    vertical-align: center; 
`;










export const PlanBanner = styled.div.attrs({ 
})`       
    height: 147px;
    border-radius: 5px;
    margin: 20px 0 0 0;

    background: url(/background/background-6.png) no-repeat center center / cover;
`;
export const PlanContent = styled.div.attrs({ 
})`       
    margin: 20px 0;
    padding: 16px 14px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;

    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--lightshadow-color);
    }
`;
export const PlanContentInfo = styled.div.attrs({ 
})`       
    flex:1;
    display: flex;
    align-items: center;
`;
export const PlanTitle = styled.div.attrs({ 
})`   
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);    
`;
export const PlanAction = styled.div.attrs({ 
})`       
    font-size: 15px;
    font-weight: bold;
    color: var(--secondary-color);
`;
export const PlanCardContent = styled.div.attrs({ 
})`       
    margin: 20px 0;
    padding: 16px 14px;
    border: 1px solid var(--shadow-color);
    border-radius: 5px;

    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--lightshadow-color);
    }
`;
export const PlanBrand = styled.img.attrs({ 
    src: `/brands/mastercard.png`
})`       
    margin-right: 10px;
`;
export const PlanCardText = styled.div.attrs({ 
})`       
`;
 