import React, { useContext, useEffect, useState } from "react"; 
import { CoreContext } from "context/CoreContext";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import moment from "moment";
import Input from 'components/Input'

import {  
    CentredContent,
    

    FormContent,
    FormTitle,
    FormText,


    SelectedProfile,
    SelectedProfileImage,
    SelectedProfileImageIcon,
    SelectProfileWrapper,
    SelectProfileImage,
    SelectedProfileTitle,

    RowChoose,
    DateInput


} from './styled' 

import ContainerProfile from "containers/Profile";
import Button from "components/Button";
import { CreateProfile, ReadProfilePictures, ReadProfiles } from "services/profile";
import { exposeStrapiError } from "utils";
import { parseProfile, parseProfileImage } from "utils/parser";

export default function SelectProfile(){ 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const [ step, setStep ] = useState(0) 
    const [ loading, setLoading ] = useState(false) 
    const [ availableProfiles, setAvailableProfiles ] = useState([])

    const { profiles, setProfiles, backgrounds } = useContext(CoreContext)

    const steps = [
        { title:'Conhecendo o seu pequenino' },
        { title:'Qual personagem o seu pequenino Gosta Mais?' }
    ]

    const [ form, setForm ] = useState({  })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const start = () => { 
        navigate(profiles && profiles?.length ? 'dashboard/select-profile' : 'dashboard')
    }  

    const valid = (verbose = false) => {  
        if(step === 0){ 
            if(!formValue('name') || !formValue('name').length ){ 
                if(verbose){ toast.error('Preencha o campo: Nome') ;}
                return false; 
            }  
            if(!formValue('gender') || !formValue('gender').title){ 
                if(verbose){ toast.error('Preencha o campo: Gênero') ;}
                return false; 
            }  
            if(!formValue('birthdate') || !formValue('birthdate').length ){ 
                if(verbose){ toast.error('Preencha o campo: Data de nascimento') ;}
                return false; 
            }  
        }  

        if(step === 1){ 
            if(!formValue('avatar') ){ 
                if(verbose){ toast.error('Escolha o avatar') ;}
                return false; 
            }   
        }  
        return true;
    }
 
    const next = () => {
        if (!valid(true)){ return ;}
        if ((step+1)<steps.length) {
            let data = moment(form.birthdate, 'DD-MM-YYYY')
            if (!data.isValid()) {
                toast.error('Data inválida')
                return
            }

            setStep(step+1)
        } else {
            action()
        }
    }

    const back = () => {
        if((step-1)>=0){
            setStep(step-1)
        }else{
            // go back page?
        }
    }


    const action = async () => { 
        setLoading(true)
       
        let data = moment(form.birthdate, 'DD-MM-YYYY') 
        const result = await CreateProfile({
            data:{
                name: form.name,
                picture: form.avatar.id,
                gender: form.gender.type,
                birthdate: data.format('YYYY-MM-DD')        
           }
        })
        
        const profiles = await ReadProfiles() 
        if(profiles?.length){ 
            setProfiles(profiles.map(parseProfile)) 
        }

        setLoading(false)
        if(result?.id && !exposeStrapiError(result)){
            setForm({})
            setStep(0) 
            navigate('dashboard/select-profile')
        }
    }

    const loadGenderProfile = async () => {
        const result = await ReadProfilePictures() 
        if(result?.data?.length){ setAvailableProfiles(result.data.map(parseProfileImage)) }
    }

    useEffect(() => {
        loadGenderProfile()
    }, [])

 
    return ( 
        <>  
            <ContainerProfile banner={backgrounds?.find(fnd => fnd.locale === 'creating_profile')?.url}>  

                <FormContent>
                    <FormTitle secondary={step === 2}>Passo { step+1 } de { steps.length }</FormTitle>
                    <FormText white={step === 2}>{ steps[step]?.title }</FormText> 
                    {
                        step == 0 ? <>
                            <Input outline placeholder="Nome" value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')}  /> 
                            <FormText label>Pequenino ou pequenina? *</FormText>
                            <RowChoose>
                                {
                                    [
                                        { title:'Menino', type:"male" },
                                        { title:'Menina', type:"female" }
                                    ].map((item, key) => 
                                        <Button key={key} secondary={item.type === 'female'} accent={item.type === 'male'} outline={formValue('gender').title !== item.title} onClick={() => changeForm(item, 'gender' )}>{ item.title }</Button>
                                    )
                                }
                            </RowChoose>
                            <FormText label>Aniversário do pequenino</FormText>
                            <DateInput outline placeholder="Data de nascimento" mask={"99/99/9999"} value={formValue('birthdate')} onChange={e => changeForm(e.target.value, 'birthdate')}  /> 
                        </> : null
                    }
                    
                    {
                        step == 1 ? <>
                            <SelectedProfile>
                                <SelectedProfileImage image={ formValue('avatar')?.image }>
                                    { formValue('avatar') ? null : <SelectedProfileImageIcon /> }
                                </SelectedProfileImage>
                                <SelectedProfileTitle>{formValue('name')}</SelectedProfileTitle>
                            </SelectedProfile>
                        </> : null
                    }
                </FormContent>

                {
                    step == 1 ? <>
                        <SelectProfileWrapper>
                            {
                                availableProfiles?.filter(fit => fit.name.indexOf(formValue('gender').type) === 0 )?.map((item, key) => {
                                    return (
                                            <SelectProfileImage key={key} image={ item?.image } onClick={() => changeForm(item, 'avatar')} active={formValue('avatar')?.id === item?.id} />
                                        )
                                })
                            }
                        </SelectProfileWrapper>
                    </> : null
                }

                <FormContent>
                    <Button primary outline onClick={start}>Criar Depois</Button>
                    <Button primary={valid()} onClick={next} loading={loading}>Avançar</Button>
                </FormContent>



            </ContainerProfile> 
        </>
    );
}

//<DateInput outline placeholder="Data de nascimento" mask={"99/99/9999"} value={formValue('birthdate')} onChange={e => changeForm(e.target.value, 'birthdate')}  /> 