import React, { useContext, useEffect, useState } from "react";  

import {    
    DashboardSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import DashboardRowVideos from "components/Dashboard/RowVideos";
import DashboardMenu from "components/Dashboard/Menu";
import CarouselBanner from "components/Dashboard/CarouselBanner";
import { availableCatagories, availableVideos } from "utils/mock";
import { ReadFavorites } from "services/favorites";
import { CoreContext } from "context/CoreContext";

export default function DashboardFavorite(){   

    const { reloadFavorites, favorites } = useContext(CoreContext)

    useEffect(() => {
        reloadFavorites()
    }, [])
 
    return ( 
        <>
            <ContainerAuthenticated primary page={'favorites'}>  
                <DashboardSpacer /> 
                <DashboardRowVideos title={'Meus Favoritos'} wrapper videos={favorites} />  
            </ContainerAuthenticated> 
        </>
    );

}