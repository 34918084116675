import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding: 0px 0 0;

    background: var(--white-color);
`; 

export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 28px;
    font-weight: bold;
    line-height: 1em;
    color: var(--primary-color);
    margin: 0;
    
    width: 100%;
    max-width: 360px;
    text-align: center;
`;  
 

export const CommomText = styled.div.attrs({  
})`          
    font-size: 18px;
    line-height: 1.2em;
    color: var(--darkprimary-color);
    margin: 15px 0 0 0;

    width: 100%;
    max-width: 360px;
    text-align: center;
`;  
 


export const CommomImageBanner = styled.img.attrs({  
    src:'/images/Topo_Site_JoinKids.jpg'
})`           
    width: 100%;
    max-width: 540px;
    margin: 15px 0 0 0;
    border-radius: 10px;

    @media(max-width: 767px){
        margin-top: 20px;
        margin-botton: 20px;
    }
`;  

export const BannerLogo = styled.img.attrs({ 
    src:'/icons/logo_lancamento.svg'
})`           
    // margin: 65px auto 20px;
    // margin: -45px auto 10px;
    margin: 0 0 0 auto 20px;
    width: 100%;
    max-width: 210px;

    `; 
export const BannerText = styled.div.attrs({ 
})`           
        font-size: 20px;
        line-height: 2em;
        color: var(--darkprimary-color);    
    
        text-align: center;
`; 


export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 10px auto 10px;
    flex:1;
    width: 160px;
`; 

export const CommomCentred = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    ${
        props => props.spaced ? `
            padding-top: 20px;
        ` : ``
    }
`;

export const ContainerLoginContent = styled.div.attrs({ 
})`          
    position: relative;
    padding: 0px 0 0;

    background: var(--primary-color);
`;

export const LoginContent = styled.div.attrs({ 
})`           
    margin: 10px auto 10px;
    align-items: right;
    width: 210px;

    //background: var(--primary-color);
    
`; 