import React, { useContext, useState } from "react";  

import {     

    FaqItem,
    FaqItemAsk,
    FaqItemAnswer,

    FaqItemHeader,
    FaqItemIcon

} from "./styled"; 

export default function Faq({ ask, answer }){    

    const [ open, setOpen] = useState(false)

    return ( 
        <>  
            <FaqItem>
                <FaqItemHeader onClick={() => setOpen(!open)}>
                    <FaqItemAsk>{ ask }</FaqItemAsk>
                    <FaqItemIcon open={open} />
                </FaqItemHeader>
                { open ? <FaqItemAnswer dangerouslySetInnerHTML={{__html: answer }} /> : null }
            </FaqItem> 
        </>
    );

}