import { CoreContext } from "context/CoreContext";
import React, { useContext, useEffect, useState } from "react";  
import { useHistory } from "react-router-dom";
import { moreOptions } from "utils/mock";
import { parseCategory } from "utils/parser";

import {    

    MenuScroll, 
    BodyMenu,
    BodyMenuItem,
    BodyMenuItemIcon,

    MenuMenu,
    MenuItem
} from "./styled"; 

export default function DashboardMenu({ tabs }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const menuOptions = [
        { title: 'Conteúdos' }, 
        { title: 'Mais', icon:'caret', options: moreOptions },
    ]

    const [opened, setOpened] = useState('') 
    const { currentCategory, setCurrentCategory } = useContext(CoreContext)

    const action = (e, item, key) => {
        if(item.options){
            if(!e.target.closest('.menu-menu')){
                setOpened( opened === item.title ? '' : item.title)
            }
        }else{ 
            setCurrentCategory(key)
        }
    }   

    const menuAction = item => {  
        navigate(`dashboard/category/${ item.id }`)
        setOpened('')
    }





    const [categories, setCategories] = useState([
        menuOptions[0],
    ])                   
    
    const init = () => {
        if(tabs?.length){
            const homeTabs = [
                menuOptions[0],
                ...[...tabs].slice(0, 3).map(parseCategory)
            ]
            if( tabs?.length > 3 ){ homeTabs.push(menuOptions[1]) ;}
            setCategories(homeTabs)
        }
    }

    useEffect(() => {
        init()
    }, [tabs])




    return ( 
        <> 
            <MenuScroll>
                <BodyMenu>
                    {
                        categories.map((item, key) => 
                        <BodyMenuItem key={key} active={ key === currentCategory } onClick={e => action(e, item, key)} >
                                { item.title }
                                { item.icon ? <BodyMenuItemIcon icon={item.icon} active={opened === item.title} /> : null }
                                {
                                    opened === item.title ? <>
                                        <MenuMenu className="menu-menu">
                                            {
                                                ( tabs?.length  > 3 ? [...tabs].slice(3).map(parseCategory) : [] )?.map((mit, mik) => 
                                                    <MenuItem key={`${key}-${mik}`} onClick={() => menuAction(mit)}>{ mit.title }</MenuItem>
                                                )
                                            }
                                        </MenuMenu>
                                    </> : null
                                }
                            </BodyMenuItem>
                        )
                    }
                </BodyMenu> 
            </MenuScroll> 
        </>
    );
}