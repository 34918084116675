import React, { useState } from "react";   
import { useHistory } from "react-router-dom";
import DashboardHeaderMenu from "../HeaderMenu";
 
// import DashboardSide from "../Side";

import { 
    DashboardHeaderContainer,
    DashboardHeaderSide,
    DashboardHeaderSideMobile,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,  
} from "./styled"; 

export default function DashboardHeader({ page }){  
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const pages = [
        { title:'Início', link:'dashboard', ref:'home' },
        { title:'Pesquisar', link:'dashboard/search', ref:'search' },
        { title:'Meus favoritos', link:'dashboard/favorites', ref:'favorites' },
        { title:'Para imprimir', link:'dashboard/downloads', ref:'downloads' }
    ]

    return ( 
        <> 
            <DashboardHeaderContainer>
                <DashboardHeaderSide>
                    <DashboardHeaderSideMobile>
                        <DashboardHeaderActionIcon src={`/icons/logo.svg`} alt="logo-icon" />
                        <DashboardHeaderSide>
                            {
                                pages.map((item, key) => 
                                    <DashboardHeaderAction key={key} active={ page === item.ref } onClick={() => navigate(item.link) }> { item.title } </DashboardHeaderAction> 
                                )
                            }
                        </DashboardHeaderSide>
                    </DashboardHeaderSideMobile> 
                </DashboardHeaderSide>
                {/*  */}
                <DashboardHeaderSide> 
                    <DashboardHeaderMenu />  
                </DashboardHeaderSide>
            </DashboardHeaderContainer> 
            {/* <DashboardSide opened={opened} setOpened={setOpened} />  */}
        </>
    );
}