import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  

import { DefaultUi, Player, Youtube } from "@vime/react";
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnTV(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Como Funciona a JoinKids</CommomTitle>
                                <CommomText>1. Escolha o plano ideal para seus pequeninos</CommomText>
                                <CommomText>2. Crie sua conta JoinKids em segundos</CommomText>
                                <CommomText>3. Comece a assistir em qualquer dispositivo</CommomText>
                                <CommomText>4. Diversão com segurança para os pequeninos</CommomText>
                                <CommomText>5. Cancele quando quiser</CommomText>
 
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner >
                                    <div >
                                        <Player>
                                            {/* <Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/> */}
                                            <Youtube videoId={`nGH3aqrny-4`} key={`nGH3aqrny-4`}/>
                                            <DefaultUi />
                                        </Player>
                                    </div>
                                </CommomImageBanner>
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}
//<Player autoplay={true}>  na linha 32