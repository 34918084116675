import React, { useContext, useEffect, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';

import Input from 'components/Input'

import { 
    FormContent,
    FormTitle,
    FormText,
    FormLabel,
    FormTouch,
    FormSpacer,
    
    PlanContent,
    PlanImage,
    PlanText,

    SelectedPlan,
    SelectedPlanTitle,
    SelectedPlanText,

    FormWrap,
    LicenseText,

    TabsContent,
    TabItem,

    WhiteCard,
    CardBanner,
    CardTitle,
    CardText

} from './styled'

import Button from "components/Button";
import SelectInstallment from "components/SelectInstallment";

import ContainerUnauthenticated from "containers/Unauthenticated";

import { CoreContext } from "context/CoreContext";
import { DoLogin, DoRegister, ReadMe, ReadPremiumPlans } from "services/authentication";
import { ReadProfiles } from "services/profile";

import { exposeStrapiError, isEmail, parseCurrency } from "utils"; 
import { parsePlan, parseProfile } from "utils/parser";
import { SubscribePlan, SubscribeProvider, ValidateCoupon } from "services/plans";
import { GetCardType, TransformExpirationDate } from "utils";

export default function Register(){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { setUser, setProfiles, setCurrentProfile, backgrounds } = useContext(CoreContext)

    const [ availablePlans, setAvailablePlans ] = useState([])
    const [ loading, setLoading ] = useState(false) 
    const [ success, setSuccess ] = useState(false) 
    const [ step, setStep ] = useState(0) 
    const [ coupon, setCoupon ] = useState(null) 
    const [ valueDiscount, setValueDiscount ] = useState(0)
    const [ provider, setProvider ] = useState('gateway')
    const [ errorPayment, setErrorPayment ] = useState(false)

    const steps = [
        { title:'Informe aqui o seu melhor e-mail' },
        { title:'Crie uma senha para sua conta' },
        { title:'Escolha seu plano:' },
        { title:'Insira os dados do seu cartão de crédito e finalize a compra' }  
    ]
    
    const [ form, setForm ] = useState({
        paymentMethod: 0
    })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const couponNotNull = () => {
        if (!formValue('coupon') || !formValue('coupon').length) {
            return false; 
        }

        return true
    }

    const valid = (verbose = false) => {  
        if (step === 0) {
            if (!formValue('email') || !formValue('email').length || !isEmail(formValue('email'))) {
                if(verbose){ toast.error('Preencha o campo: Email') ;}
                return false;
            }  
        } 
        if (step === 1) {
            if (!formValue('password') || !formValue('password').length) {
                if(verbose){ toast.error('Preencha o campo: Senha') ;}
                return false;
            }
        }

        if (step === 3) {
            if (formValue('paymentMethod') == 1) {
                // invoice
                if (!formValue('document') || !formValue('document').length) {
                    if(verbose){ toast.error('Preencha o campo: CPF') ;}
                    return false;
                }
            }

            if (formValue('paymentMethod') == 0) {
                // credit card
                if (!formValue('card_name') || !formValue('card_name').length) {
                    if(verbose){ toast.error('Preencha o campo: Nome') ;}
                    return false;
                }
                if (!formValue('card_number') || !formValue('card_number').length) {
                    if(verbose){ toast.error('Preencha o campo: Número do cartão') ;}
                    return false;
                }
                if (!formValue('card_expires') || !formValue('card_expires').length || ![5, 7].includes(formValue('card_expires').replace(/\ /g, '').length)) {
                    if(verbose){ toast.error('Preencha o campo: Validade') ;}
                    return false;
                }
                if (!formValue('card_cvc') || formValue('card_cvc').replace(/\ /g, '').length < 3) {
                    if(verbose){ toast.error('Preencha o campo: Cod. Segurança') ;}
                    return false;
                }
                // if(!formValue('card_installment') || !formValue('card_installment').length){ 
                //     if(verbose){ toast.error('Selecione o parcelamento') ;}
                //     return false; 
                // }
                // if(!formValue('card_document_user') || !formValue('card_document_user').length){ 
                //     if(verbose){ toast.error('Preencha o campo: CPF ou CNPJ do Titular') ;}
                //     return false; 
                // }
            }
        }
        return true
    } 

    const action = () => {
        if (!valid(true)) { return ;}

        if (step == 1 && provider != 'gateway') {
            actionLogin(provider)
        } else if ((step + 1) < steps.length) {
            setStep(step+1)
        } else {
            actionLogin()
        }
    }

    const back = () => {
        if((step-1)>=0){
            setStep(step-1)
        }else{
            // go back page?
        }
    }

    const showSuccess = () => {
        const cookieActionpay = getCookieLocal('Actionpay')

        if (cookieActionpay) {
            try {
                fetch("https://apretailer.com.br/ok/23736.png?actionpay=" + cookieActionpay + "&apid=" + encodeURIComponent(formValue('email')) + "&price=" + formValue('plan').value)
            } catch(e) {}
        }

        setSuccess({
            title:'Parabéns!!',
            text:'Compra Finalizada com Sucesso',
            background: backgrounds?.find(fnd => fnd.locale === 'success_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'success_purchase_header')?.url,
            primary:{
                action: () => start(),
                label: 'Começar'
            }, 
        });
    }

    const showRetry = () => {
        setSuccess({
            title:'Ops! =(',
            text:'Verifique com seu banco. Pagamento não efetuado',
            background: backgrounds?.find(fnd => fnd.locale === 'failed_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'failed_purchase_header')?.url,
            primary:{
                action: () => signPlan(),
                label: 'Tentar novamente'
            }
        });
    }

    const showInvoiced = () => {
        setSuccess({
            title:'Quase lá!',
            text:'Faça o download do PDF ou Copie o código de barra, que aguardaremos a confirmação de pagamento. Por segurança enviamos o Boleto no seu e-mail.',
            background: backgrounds?.find(fnd => fnd.locale === 'success_purchase')?.url ,
            banner: backgrounds?.find(fnd => fnd.locale === 'success_purchase_header')?.url,
            big: true,
            primary:{
                action: () => null,
                label: 'Download do PDF'
            },
            secondary:{
                action: () => copyInvoice(),
                label: 'Copiar código de Barra'
            },
            outline:{
                action: () => start(),
                label: 'Começar'
            },
        });
    }

    const actionLogin = async (provider = null) => {

        //mock action
            // if(formValue('paymentMethod') == 0){
            //     showRetry()                                                                                                                                           
            // }else{
            //     showInvoiced()
            // }
            // return ;
            
        setLoading(true)
        
        const result = await DoRegister({
            ...form, 
            username: form.email?.replace(/ /g,''),
            email: form.email?.replace(/ /g,''),
            confirmed: true,
            blocked: false,
            payment_type: provider
        })  
        
        if (!(result && !exposeStrapiError(result))) {
            setLoading(false)
            return;
        } 

        await loginBefore()
        setLoading(false)

        if (provider == 'giftcard') {
            signGiftCard()
        } else {
            signPlan()
        }
    }

    const validateCoupon = async () => {
        const plan = formValue('plan')
        const coupon = formValue('coupon')

        if (coupon) {
            const resultCoupon = await ValidateCoupon({ coupon })

            if (resultCoupon && !exposeStrapiError(resultCoupon)) {
                setCoupon(resultCoupon)

                const percent = plan.id == 2 ? resultCoupon.percent_month : resultCoupon.percent_year
                let newValue = parseFloat((plan.value - (plan.value * percent) / 100).toFixed(2))

                setValueDiscount(newValue)
            }
        }
    }

    const signPlan = async () => { 
        setLoading(true)
        const CardNumber = formValue('card_number')?.replace(/\ /g, '')
        const Brand = GetCardType(CardNumber)
        const ExpirationDate = TransformExpirationDate(formValue('card_expires'))

        const payload = {
            data: {
                credit_card: {
                    CardNumber,
                    Holder: formValue('card_name'),
                    ExpirationDate,
                    SecurityCode: formValue('card_cvc')?.replace(/\ /g, ''),
                    Brand
                },
                plan: formValue('plan')?.id,
                coupon_id: coupon ? coupon.id : null
            }
        }

        const result = await SubscribePlan(payload)
        
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            showSuccess()
        } else{
            showRetry()
        }
    }

    const signGiftCard = async () => {
        setLoading(true)
        const result = await SubscribeProvider({ ...form, provider })
        setLoading(false)

        if (result && !exposeStrapiError(result)) {
            setErrorPayment(false)
            showSuccess()
        } else {
            setErrorPayment(true)
        }
    }

    const copyInvoice = () => {
        toast.info('Copiado'); 
    }   

    const loginBefore = async () => {  
        const result = await DoLogin({ ...form, identifier: form.email?.replace(/\ /g,'') })  
        if(result && !exposeStrapiError(result)){
            await completeLogin(result)
        } 
        return true;
    }
 
    const completeLogin = async (result) => {
        if(result?.user){ setUser(result.user) }
        const profiles = await ReadProfiles()
        const me = await ReadMe() 

        if(me?.active_profile){
            setCurrentProfile(parseProfile(me?.active_profile))
        }

        if(profiles?.length){ 
            setProfiles(profiles.map(parseProfile))
        }
        return true;
    }  

    const start = async () => { 
        navigate('dashboard/create-profile') 
    }

    const selectPlan = item => { 
        changeForm(item, 'plan')
        action()
    }

    const paymentOptions = [
        { title: 'Cartão de crédito' },
        { title: 'Boleto' },
    ]

    const installmentOptions = () => {
        const fullValue = formValue('plan')?.value
        return fullValue ? [1,2,3,4,5,6,7,8,9,10].map(installments => ({ value: parseFloat(fullValue/installments).toFixed(2), installments })) : []
    }  

    const init = async () => {
        const result = await ReadPremiumPlans()
        if(result?.plans?.length){
            setAvailablePlans(result.plans.map(item => parsePlan(item, backgrounds))) 
        }

        const queryParam = new URLSearchParams(window.location.search)
        const providerUrl = queryParam.get('provider');
        if (providerUrl) {
            setProvider(providerUrl)
        } 
    }

    const getCookieLocal = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        if (decodedCookie === "undefined") {
            return "";
        }
        else {
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return null;
        }
    }

    useEffect(() => {
        init()
    }, [])
 
    return ( 
        <>  
            {
                success ? <>
                    <ContainerUnauthenticated image={success.background}> 
                        <FormContent>
                            <WhiteCard>
                                <CardBanner image={ success.banner } />
                                <CardTitle>{ success.title }</CardTitle>
                                <CardText big={success.big}>{ success.text }</CardText>
                            </WhiteCard>
                            { success.primary ? <Button primary onClick={success.primary.action}>{ success.primary.label }</Button> : null }
                            { success.secondary ? <Button secondary onClick={success.secondary.action}>{ success.secondary.label }</Button> : null }
                            { 
                                success.outline ? <>
                                    <FormSpacer  /> 
                                    <Button white outline onClick={success.outline.action}>{ success.outline.label }</Button>
                                </> : null 
                            }
                        </FormContent>
                    </ContainerUnauthenticated>
                </> : <>
                    <ContainerUnauthenticated primary={step === 2} banner={step === 2 ? null : backgrounds?.find(fnd => fnd.locale === 'checkout')?.url }>  
                        <FormContent big={(step === 2 || step === 3)}>
                            {
                                step === 3 ? <>
                                    <FormSpacer /> 
                                    <FormText>Plano selecionado :</FormText>
                                    <FormTouch onClick={back}>
                                        <SelectedPlan>
                                            <SelectedPlanTitle>{ formValue('plan')?.title }</SelectedPlanTitle>
                                            {
                                                formValue('plan')?.installment ? 
                                                <SelectedPlanText><b>{ parseCurrency(coupon ? valueDiscount : formValue('plan')?.value) }</b></SelectedPlanText>
                                                    :
                                                <SelectedPlanText><b>{ parseCurrency(coupon ? valueDiscount : formValue('plan')?.value) }</b></SelectedPlanText>
                                            }
                                        </SelectedPlan>
                                    </FormTouch>
                                </> : null
                            }

                            {
                                provider != 'giftcard' ? <>
                                    <FormTitle secondary={step === 2}>Passo { step+1 } de { steps.length }</FormTitle>
                                </> : null
                            }
                            
                            {
                                step === 3 ? <>
                                    <FormText>Possui um cupom?</FormText> 
                                    <Input outline placeholder="Insira o cupom" value={formValue('coupon')} onChange={e => changeForm(e.target.value, 'coupon')} />
                                    <Button secondary={couponNotNull()} loading={loading} onClick={validateCoupon}>Aplicar</Button>
                                    <br></br>
                                </> : null
                            }

                            {
                                provider === 'giftcard' ? <>
                                    <FormText>Insira o código do cartão</FormText>
                                    <Input outline placeholder="Cartão Pré-pago" type="text" value={formValue('giftcard')} onChange={e => changeForm(e.target.value, 'giftcard')}  />
                                    <br></br>
                                </> : null
                            }

                            <FormText white={step === 2}>{ steps[step]?.title }</FormText> 

                            {
                                step === 0 ? <>
                                    <Input outline placeholder="E-mail" value={formValue('email')} onChange={e => changeForm(e.target.value.trim(), 'email')} /> 
                                </> : null
                            }
                            {
                                step === 1 ? <>
                                    <FormTouch onClick={back}>
                                        <FormLabel>Email</FormLabel> 
                                        <FormText>{ formValue('email') }</FormText> 
                                    </FormTouch>
                                    <Input outline placeholder="Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')}  />
                                </> : null
                            }

                            {
                                step === 3 ? <> 
                                        {/* <TabsContent>
                                            {
                                                paymentOptions.map((item, key) => 
                                                    <TabItem key={key} active={key == formValue('paymentMethod') } onClick={() => changeForm(key, 'paymentMethod')}>{ item.title }</TabItem>
                                                )
                                            }
                                        </TabsContent> */}

                                        {
                                            formValue('paymentMethod') == 0 ? <>
                                                {/* <FormText><a href="https://wa.me/5592982168732?text=Eu%20quero%20assinar%20com%20PIX!">Para pagamento em PIX, clique aqui e fale conosco</a></FormText> */}
                                                <Input outline placeholder="Nome (como está escrito no cartão)" value={formValue('card_name')} onChange={e => changeForm(e.target.value, 'card_name')}  />
                                                <FormSpacer small /> 
                                                <Input outline mask={"9999 9999 9999 9999"} placeholder="Número do Cartão" value={formValue('card_number')} onChange={e => changeForm(e.target.value, 'card_number')}  />
                                                <FormSpacer small /> 
                                                <FormWrap>
                                                    <Input outline mask={"99/9999"} placeholder="Validade (MM/AAAA)" value={formValue('card_expires')} onChange={e => changeForm(e.target.value, 'card_expires')}  />
                                                    <Input outline mask={"999"} placeholder="Cod. Segurança" value={formValue('card_cvc')} onChange={e => changeForm(e.target.value, 'card_cvc')}  />
                                                </FormWrap>

                                                {/* <FormWrap>
                                                    <SelectInstallment outline placeholder="Parcelamento" value={formValue('card_installment')} onChange={e => changeForm(e.target.value, 'card_installment')} options={installmentOptions()} />
                                                </FormWrap> */}
                                                {/* <FormSpacer small />  */}
                                                {/* <Input outline placeholder="CPF ou CNPJ do Titular" value={formValue('card_document_user')} onChange={e => changeForm(e.target.value, 'card_document_user')}  /> */}

                                                <LicenseText>
                                                    Ao clicar no botão “Finalizar Compra” abaixo, você concorda com nossos <a target={'new'} href={'/privacity-police'}>Termos de uso</a> e com nossa <a target={'new'} href={'/privacity-police'}>Declaração de privacidade</a>, confirma ter mais de 18 anos e aceita que a <b>Join Kids renove automaticamente sua assinatura e cobre o preço da assinatura (atualmente {formValue('plan')?.renew}) da sua forma de pagamento até você cancelar. Você pode cancelar quando quiser para evitar cobranças futuras.</b> Para cancelar, acesse o nosso site, faça o login em sua conta, clique no seu avatar, depois em "Minha Conta", clique no seu Plano e em seguida em "Cancelar Assinatura".
                                                </LicenseText>
                                            </> : null
                                        }
                                        
                                        {
                                            formValue('paymentMethod') == 1 ? <>
                                                <Input outline placeholder="CPF" value={formValue('document')} onChange={e => changeForm(e.target.value, 'document')}  />
                                                <LicenseText>
                                                    Faça o <b>download</b> do PDF ou Copie o <b>código de barra</b>, que aguardaremos a confirmação de pagamento. Por segurança enviamos o Boleto no seu e-mail.
                                                </LicenseText>
                                            </> : null
                                        }
                                        
                                </> : null
                            }

                            {
                                
                                step === 2 ? <>  
                                    {
                                        availablePlans.map((item, key) => 
                                            <PlanContent key={key}>
                                                <PlanImage image={item.image} />
                                                <Button { ...item.variant } loading={loading} onClick={() => selectPlan(item)} >{ item.action }</Button>
                                                <PlanText>{ item.text }</PlanText>
                                            </PlanContent>
                                        )
                                    }  
                                </> : <>
                                    <FormSpacer /> 
                                    {
                                        provider === 'giftcard' ? <>
                                            {
                                                errorPayment ? <>
                                                    <Button secondary={valid()} loading={loading} onClick={signGiftCard}>Tentar novamente</Button>
                                                </> : <>
                                                    <Button secondary={valid()} loading={loading} onClick={action}>{ step >= 1 ? 'Finalizar' : 'Avançar' }</Button>
                                                </>
                                            }
                                            
                                        </> : <>
                                            <Button secondary={valid()} loading={loading} onClick={action}>{ (step+1) === steps.length ? 'Finalizar Compra' : 'Avançar' }</Button> 
                                        </>
                                    }
                                </>
                            }

                            <FormSpacer /> 
                        </FormContent>
                    </ContainerUnauthenticated> 
                </>
            }
        </>
    );
}