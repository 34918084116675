import styled from 'styled-components';

// Container principal para o título e as redes sociais
export const Container = styled.div.attrs({ 
})`           
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

// Título centralizado e responsivo
export const Title = styled.div.attrs({ 
})`           
  font-size: 3vw;
    font-weight: bold;
    line-height: 2em;
    text-align: center;
    color: var(--primary-color);
    margin: 0;
    padding: 10px;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 6vw;
    max-width: 260px;
    line-height: 1em;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 2rem;
  }

`; 

// Container para organizar os ícones das redes sociais
export const SocialIconsContainer = styled.div.attrs({ 
})`
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;

  ${
    props => props.spaced ? `
        padding-top: 100px;
    ` : ``
    }

  // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    gap: 8px;
    
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    gap: 60px;
  }

`;

// Estilo para cada ícone circular
export const SocialIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Estilo do ícone circular
export const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f0f0f0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  
  &:hover {
    background-color: #ddd;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

// Texto abaixo do ícone
export const SocialText = styled.span`
  font-size: 0.9rem;
  color: #555;
  text-align: center;
`;