import React, { useContext, useState } from "react";  
import { toast } from 'react-toastify';

import {    
    PageText, 
    ContentCheck,
    CheckItem,
    CheckItemIcon 
} from "./styled"; 
export default function Check({ children, onChange, checked }){   

    const [isCheck, setIsCheck] = useState(!!checked)

    const toggleCheck = () => {
        if(onChange && typeof onChange === 'function'){ onChange(!isCheck) ;}
        setIsCheck(!isCheck)
    }

    return ( 
        <> 
            <ContentCheck onClick={toggleCheck}>
                <CheckItem checked={isCheck}>
                    { isCheck ? <CheckItemIcon /> : null }
                </CheckItem>
                <PageText>
                    { children }
                </PageText>
            </ContentCheck>
        </>
    );

}