import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 40px;
`;



export const PageTitle = styled.div.attrs({ 
})`              
    font-size: 30px;
    font-weight: bold;
    color: var(--black-color);
    margin-top: 40px;
    
    text-align: center;
`;
export const PageSubtitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
    margin-top: 20px; 
`;
export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-top: 20px;           
`;

export const PageResendAction = styled.div.attrs({ 
})`      
    display: flex;
    max-width: 200px; 
`;

export const PageActions = styled.div.attrs({ 
})`      
    display: flex;
    align-items: center;
    gap: 0 20px;
`;

export const BigInput = styled.input.attrs({ 
})`      
    
    font-size: 34px;
    font-weight: bold;
    color: var(--black-color);
    padding: 0 0 0 20px;
    width: 100%;
    height: 65px;
    background: none;
    outline: none;
    margin-top: 20px;
    border: 1px solid var(--shadow-color);
    border-radius: 10px; 
    letter-spacing: 30px;
    vertical-align: center;
    text-align: center;
`;



 