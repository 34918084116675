import styled from 'styled-components';

// Container principal para o título e as imagens
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  background: var(--primary-color);
`;

// Título responsivo e centralizado
export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 3vw;
    font-weight: bold;
    line-height: 3em;
    text-align: center;
    color: var(--white-color);
    margin: 0;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 6vw;
    max-width: 360px;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }

`; 

// Container das imagens, organizadas horizontalmente em telas grandes e verticalmente em mobile
export const ImagesContainer = styled.div`          
  display: flex;
  justify-content: center;
  //flex-wrap: wrap; // Permite que as imagens quebrem linha em telas menores
  gap: 20px; // Espaçamento entre as imagens

    @media (max-width: 768px) {
    width: 140%;
    flex-wrap: wrap; // Permite que as imagens quebrem linha em telas menores

`;

// Imagem responsiva
export const Image = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 10px auto 10px;
    flex:1;
    width: 210px;
`; 

export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 18px;
    color: var(--darkprimary-color);    

    text-align: center;

`;

export const ColorLink = styled.div.attrs({ 
})`           
    color: var(--secondary-color);
    text-decoration: underline;  
`;

// Estilo do quadrado com cantos arredondados
export const Card = styled.div`
  background-color: #f0f0f0;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 60%;
  padding: 10px;
  text-align: center;
`;

// Imagem circular no topo do quadrado
export const CircleImageContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
  margin: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5%;
  }
`;

// Texto dentro do quadrado, abaixo da imagem
export const CardText = styled.div.attrs({  
})`          
    font-size: 3vw;
    font-weight: bold;
    line-height: 3em;
    text-align: center;
    color: var(--primary-color);
    margin: 0;
    
    width: 100%;
    max-width: 560px;

    // Ajuste para dispositivos móveis (abaixo de 768px)
  @media (max-width: 768px) {
    font-size: 4vw;
    max-width: 360px;
    line-height: 1.3em;
  }

  // Ajuste para telas muito grandes
  @media (min-width: 1200px) {
    font-size: 1.2rem;
  }

`; 

export const InfoList = styled.ul`
  list-style-type: disc; /* Adiciona os bullets padrão */
  padding-left: 20px;  /* Recuo para alinhar bullets */
  margin-top: 10px;
  text-align: left;

  li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
`;