import React, { useEffect } from "react";  
import { useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';   
import { ReadObject } from "services/storage"; 

import { 
    FormContent, 
    Content, 
    Banner
} from './styled'
 

export default function ContainerUnauthenticated({ children, image, banner, primary }){   
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.jwt) {
            completeNext()
        }
    }
 
    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {  
        init() 
    }, [])

    return ( 
        <> 
            <Content image={image} primary={primary}>
                <FormContent> 
                    { banner ? <Banner image={banner} /> : null }
                    <Container>
                        <Row> 
                            <Col md={{ size:4 }}></Col>
                            <Col md={{ size:4 }}>
                                    { children }
                            </Col>
                            <Col md={{ size:4 }}></Col>
                        </Row>  
                    </Container>
                </FormContent>
            </Content>
        </>
    );
}