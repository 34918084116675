import { CoreContext } from "context/CoreContext";
import React, { useContext, useState } from "react";   
import { useHistory } from "react-router-dom";
import { DoLogout } from "services/authentication";
  
import {   
    
    HeaderMenu,
    HeaderMenuImage,
    HeaderMenuIcon,


    HeaderMenuContent,
    HeaderMenuContentSection,
    HeaderMenuUser,

    HeaderMenuUserImage,
    HeaderMenuOption
} from "./styled"; 

export default function DashboardHeaderMenu(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
    const [ menuOpened, setMenuOpened ] = useState(false) 
    const { setCurrentProfile, currentProfile, profiles, setProfiles } = useContext(CoreContext)

    const selectProfile = item => {
        setCurrentProfile(item)
        setMenuOpened(false)
    } 

    const exit = async () => {

        setProfiles([])
        setCurrentProfile(null)

        await DoLogout()
        navigate('login')
    } 

    return ( 
        <>   
                <HeaderMenu onClick={() => setMenuOpened(!menuOpened)}>
                    { currentProfile?.name ? currentProfile?.name : 'Menu' }
                    <HeaderMenuImage image={ currentProfile?.avatar?.image ? currentProfile?.avatar?.image : '/images/users/face.png' } />
                    <HeaderMenuIcon /> 
                </HeaderMenu>
                {
                    menuOpened ?
                        <HeaderMenuContent>
                            <HeaderMenuContentSection> 
                                {
                                    profiles?.filter(fit => fit.id !== currentProfile.id)?.slice(0,2).map((item, key) => 
                                        <HeaderMenuUser key={key} onClick={() => selectProfile(item)}>
                                            <HeaderMenuUserImage image={item.avatar?.image} /> 
                                            { item.name }
                                        </HeaderMenuUser>
                                    )
                                } 
                                <HeaderMenuOption onClick={() => navigate('dashboard/select-profile')}>Gerenciar perfis</HeaderMenuOption>
                            </HeaderMenuContentSection>
                            <HeaderMenuContentSection white>
                                <HeaderMenuOption onClick={() => navigate('dashboard/account')}>Minha conta</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => navigate('dashboard/help')}>Ajuda</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => navigate('dashboard/about')}>Sobre</HeaderMenuOption>
                                <HeaderMenuOption onClick={() => exit() }>Sair</HeaderMenuOption>
                            </HeaderMenuContentSection>
                        </HeaderMenuContent>
                    : null
                } 
        </>
    );
}

