import styled from 'styled-components'  
 
export const LoginSpacer = styled.div.attrs({ 
})`            
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 20px;
`;
 

export const CentredContent = styled.div.attrs({ 
})`  
    padding-top: 310px;
    max-width: 325px;      
    margin: 0 auto;
`;

export const RegisterForgot = styled.div.attrs({ 
})`           
    display: flex;
    font-size: 15px; 
    color: var(--white-color);
    justify-content: flex-end; 
    margin-top: 20px;
`;

export const ForgotLink = styled.div.attrs({ 
})`           
    margin-left: 6px;
    color: var(--white-color);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({ 
})`           
    margin: 30px 0 0px;
    color: var(--white-color);
    font-size: 15px;
    font-weight: bold;
`;