import React, { useContext, useEffect, useState } from "react";  
import { toast } from 'react-toastify';

import moment from "moment";
import 'moment/locale/pt-br';

import {     

    FormContent,
    WhiteCard,
    CardBanner,
    CardTitle,
    CardText
 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import ContainerProfile from "containers/Profile";

import { CoreContext } from "context/CoreContext";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Check from "components/Dashboard/Check";
import { CancelSubscription, CheckPlan } from "services/plans";
import { exposeStrapiError } from "utils";

export default function DashboardAccountCancelSubscription(){   
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const cancel = async () => {
        setLoading(true)
        const result = await CancelSubscription()
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success('Plano cancelado com sucesso') ;
            navigate('dashboard/account')
        }   
    }



    const [currentPlan, setCurrentPlan] = useState(false)
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await CheckPlan(); 
        if(result && result.recurrency && !result?.subscription?.canceled){
            setCurrentPlan(result)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])
     
    return ( 
        <>
            <ContainerProfile image={'/background/background-8.png'}> 
                <FormContent>
                    <WhiteCard>
                        <CardBanner image={ '/background/background-a.png' } />
                        <CardTitle>Por que? :(</CardTitle>
                        <CardText>Você está prestes a cancelar o seu plano de assinatura. Esperamos que você retorne em breve.</CardText>
                        {/* <CardText>Você está prestes a cancelar o seu plano de assinatura. Você poderá continuar utilizando a Join Kids normalmente até sua próxima data de vencimento <b>{ moment(currentPlan?.recurrency?.RecurrentPayment?.NextRecurrency).format('L') }</b></CardText> */}
                    </WhiteCard>
                    <Button primary loading={loading} onClick={cancel}>Cancelar assintura</Button>
                    <Button secondary onClick={() => history.goBack()}>Não cancelar</Button>
                </FormContent>
            </ContainerProfile> 
        </>
    );

}