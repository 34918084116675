import { GET, POST, PUT, DELETE } from './api'

export const CheckPlan = async () => {
    return await GET(`/subscription`, true);
}

export const SubscribePlan = async (params) => {
    return await POST(`/subscription`, params, true);
}

export const SubscribeProvider = async (params) => {
    return await POST(`/subscription/provider`, params, true);
}

export const ChangePlan = async (params) => {
    return await PUT(`/subscription/plan`, params, true);
}

export const UpdatePlanCard = async (params) => {
    return await PUT(`/subscription/card`, params, true);
} 

export const CancelSubscription = async () => {
    return await DELETE(`/subscription`, true);
}

export const ValidateCoupon = async (params) => {
    return await POST(`/coupon/validate`, params);
}
