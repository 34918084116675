import styled from 'styled-components'  
 
export const FormContent = styled.div.attrs({ 
})`           
    max-width: 325px;
    margin: 0 auto;
    ${
        props => props.big ? `
            max-width: 425px;
        ` : ``
    } 
`;
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: var(--primary-color); 
    font-weight: bold;
    font-size: 14px; 

    margin: 50px 0 10px;
    ${
        props => props.secondary ? `
            color: var(--secondary-color);
        ` : ``
    }
`;
export const FormText = styled.div.attrs({ 
})`           
    color: var(--grey-color);
    font-size: 18px; 
    font-weight: bold;

    margin-bottom: 30px;
    ${
        props => props.white ? `
            color: var(--white-color);
        ` : ``
    }
`;

export const FormTouch = styled.div.attrs({ 
})`           
    cursor: pointer;
`;

export const FormWrap = styled.div.attrs({ 
})`           
    display: flex;
    align-items: flex-start;
    gap: 0 20px;
`;

export const FormLabel = styled.div.attrs({ 
})`           
    color: var(--grey-color);
    font-size: 14px; 
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
    ${
        props => props.small ? `
            margin-top: 20px;
        ` : ``
    }
`;
 




export const PlanContent = styled.div.attrs({ 
})`            
    padding-bottom: 20px;
`;
export const PlanImage = styled.div.attrs({ 
})`            
    width: 100%;
    height: 150px;
    background: url(${ props => props.image }) no-repeat center center / cover;
`;

export const PlanText = styled.div.attrs({ 
})`            
    margin: 20px 0;
    font-size: 14px;
    color: var(--white-color);
    text-align: center;
`;




export const SelectedPlan = styled.div.attrs({ 
})`             
    padding: 16px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    margin-bottom: -20px;
`;
export const SelectedPlanTitle = styled.div.attrs({ 
})`         
    color: var(--black-color);
    font-size: 18px; 
    font-weight: bold;    
`;
export const SelectedPlanText = styled.div.attrs({ 
})`             
    color: var(--black-color);
    font-size: 14px; 
    b{
        font-size: 18px; 
        font-weight: bold;   
    }
`;


export const LicenseText = styled.div.attrs({ 
})`             
    padding-top: 40px;
    color: var(--black-color);
    font-size: 14px; 
    text-align: justify;
    b { 
        font-weight: bold;   
    }
    a { 
        color: var(--primary-color) !important;
        cursor: pointer;
    }
`;




export const TabsContent = styled.div.attrs({ 
})`             
    display: flex;
    align-items: center;
    gap:0 20px;
    padding: 0 0 40px;
`;
export const TabItem = styled.div.attrs({ 
})`         
    color: var(--black-color);
    font-size: 18px; 
    font-weight: bold;
    padding: 5px;
    flex:1;
    text-align: center;
    margin: 0 0 5px;
    cursor: pointer;
    ${
        props => props.active ? `
            border-bottom: 2px solid var(--primary-color);
        ` : `
            border-bottom: 2px solid var(--lightgrey-color);
        `
    }
`;



 
export const WhiteCard = styled.div.attrs({ 
})`             
    margin-top: 199px;
    padding: 0px 0px 28px;
    background: var(--white-color);
    border-radius: 10px;

    margin-bottom: 40px;
`;
export const CardBanner = styled.div.attrs({ 
})`             
    height: 132px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: url(${ props => props.image }) no-repeat center center / cover;
    
    margin-bottom: 28px;
`;
export const CardTitle = styled.div.attrs({ 
})`         
    color: var(--primary-color);
    font-size: 30px; 
    font-weight: bold;     
    text-align: center;

    margin-bottom: 10px;
`;
export const CardText = styled.div.attrs({ 
})`           
    color: var(--black-color);
    font-size: 18px;     
    text-align: center;

    max-width: 200px;
    ${
        props => props.big ? `
            max-width: 310px;
        ` : ``
    }
    margin: 0 auto;
`;