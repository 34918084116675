import React, { useState } from 'react';
import { CommomContainer, FAQContainer, FAQTitle, Question, QuestionText, Answer } from './styled';

// Dados das perguntas e respostas
const faqData = [
  {
    question: "O que é a JoinKids?",
    answer: "JoinKids é um serviço de streaming de vídeos cristãos voltado para crianças de até 07 anos de idade, oferecendo conteúdos seguros e educativos."
  },
  {
    question: "Como posso me inscrever na JoinKids?",
    answer: "Você pode se inscrever na JoinKids acessando nosso site, clicando na opção Assinar agora, escolher o seu Plano e preenchendo as informações necessárias."
  },
  {
    question: "É possível cancelar a assinatura a qualquer momento?",
    answer: "Sim, você pode cancelar sua assinatura a qualquer momento diretamente pelo seu perfil no site."
  },
  {
    question: "Quais são os métodos de pagamento aceitos?",
    answer: "Aceitamos pagamentos via cartão de crédito diretamente pelo nosso site. Por PIX também é possível somente através do nosso canal de vendas pelo WhatsApp."
  },
  {
    question: "Em quais dispositivos eu posso assitir?",
    answer: "Você pode assistir a todos os nossos vídeos a partir de celulares (Android e iOS), tablets (Android e iOS), Smart TVs da Samsung, Smart TVs da LG, Computadores, Browser de Internet das Smart TVs."
  },
  {
    question: "Posso fazer download dos vídeos para assistir sem internet?",
    answer: "Sim. Esta opção está habilitada apenas para celulares e tablets."
  },
  {
    question: "Onde eu encontro os Livros Digitais Animados?",
    answer: "Os Livros Digitais Animados estão disponível no aplicativo do celular. Por favor, procure e clique na opção chamada TIQ no canto direito inferior do aplicativo."
  },
  {
    question: "Como instalar o aplicativo na minha Smart TV Samsung?",
    answer: "O nosso app está disponível nas Smart TVs da Samsung fabricadas a partir do ano de 2019. Em sua TV, procurar por Apps e em seguida pesquisar por joinkids e instalar. Após a instalação, você precisará gerar o código de acesso através do aplicativo da JoinKids em seu celular."
  },
  {
    question: "Como instalar o aplicativo na minha Smart TV LG?",
    answer: "O nosso app está disponível nas Smart TVs da LG fabricadas a partir do ano de 2020. Em sua TV, procurar por LG Content Store e em seguida pesquisar por joinkids e instalar. Após a instalação, você precisará gerar o código de acesso através do aplicativo da JoinKids em seu celular."
  },
  {
    question: "Como gerar o código de Acesso para assistir os vídeos na minha TV?",
    answer: "Você entra no aplicativo JoinKids que já foi instalado em seu celular. Clicar em Perfil. Depois clicar em Acewsso a Tv. Clicar em Gerar código."
  },
  {
    question: "Meu celular não está gerando o código de Acesso a Tv. O que eu faço?",
    answer: "No aplicativo da JoinKids em seu celular, clicar em Perfil, depois em Sair. Após isso, logar novamente no App, colocando o seu e-mail e a sua senha. Após isso, clicar em Perfil, Acesso a Tv e Gerar código."
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Função para alternar entre mostrar e esconder a resposta
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <FAQTitle>Perguntas Frequentes</FAQTitle>
      {faqData.map((item, index) => (
        <div key={index}>
          <Question onClick={() => toggleAnswer(index)}>
            <QuestionText>{item.question}</QuestionText>
          </Question>
          {activeIndex === index && <Answer>{item.answer}</Answer>}
        </div>
      ))}
        {/* <CommomContainer>
        <QuestionText>JoinKids - Todos os direitos reservados</QuestionText>
        </CommomContainer> */}
    
    </FAQContainer>
    
    
  );
};

export default FAQ;
