import styled from 'styled-components'  

 









export const VideoTouch = styled.div.attrs({ 
})`       
    cursor: pointer;
    width: 100vw;
    height: 100vh; 
    max-width: 100%;
`;













export const ContainerVideoPlayer = styled.div.attrs({ 
})`      
    position: absolute;
    inset: 0 0 0 0;
    overflow: hidden;
`;


export const ContainerVideo = styled.div.attrs({ 
})`      
    position: absolute;
    inset: 0 0 0 0;
    
    background: -moz-linear-gradient(227deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 60%);
    background: -webkit-linear-gradient(227deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 60%);
    background: linear-gradient(227deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 60%);

    overflow: auto;
`;














export const VideoHeader = styled.div.attrs({ 
})`      
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    ${
        props => props.preview ? `
            background: var(--primary-color);
            margin-bottom: 40px;
            padding: 15px 40px 0px;
        ` : `
            padding: 40px;
        `
    }

    @media(max-width: 767px){
        flex-direction: column;
        align-items: initial;
    }
`;
export const VideoHeaderSection = styled.div.attrs({ 
})`      
    display: flex;
    align-items: center;
    ${
        props => props.flex ? `
            flex:1;
        ` : ``
    }
`;
export const VideoHeaderBack = styled.img.attrs({ 
    src: '/icons/chevron.svg',
    width: 24
})`      
    transform: rotate(90deg);
    margin: 0 20px 0 0 ;

    cursor: pointer;
`;
export const VideoHeaderImage = styled.div.attrs({ 
})`      
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 40px;
    overflow: hidden;
    background: url(${ props => props.image }) no-repeat center center / cover;

    border: 5px solid var(--white-color);
`;
export const VideoHeaderContent = styled.div.attrs({ 
})`      
    padding: 0 0 0 20px;
`;
export const VideoHeaderSubtitle = styled.div.attrs({ 
})`      
    font-size: 20px; 
    font-weight: bold;
    color: var(--white-color);
`;
export const VideoHeaderTitle = styled.div.attrs({ 
})`     
    font-size: 35px; 
    font-weight: bold;
    color: var(--white-color); 
`;
export const VideoHeaderAction = styled.div.attrs({ 
})`     
    width: 52px;
    height: 52px;
    border-radius: 26px; 
    background: var(--whiteshadow-color);

    display: flex;
    align-items: center;
    justify-content: center; 
    margin-left: auto;

    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--whiteshadow-color);
    }
`;
export const VideoHeaderActionIcon = styled.img.attrs({ 
    src: '/icons/fullscreen.svg'
})`      
    ${
        props => props.active ? `
            transform: rotate(90deg);
        ` : `
        `
    }
`;















export const VideoCenterContent = styled.div.attrs({ 
})`      
    padding: 18vh 0;
    display: flex;
    justify-content: center; 
`;
export const VideoCenterAction = styled.img.attrs({ 
    src:'/icons/next.svg',
    width: 40
})`      
    ${
        props => props.next ? `
        ` : `
            transform: rotate(180deg);
        `
    }
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.2);
        ${
            props => props.next ? `
            ` : `
                transform: rotate(180deg) scale(1.2);
            `
        }
    }
`;
export const VideoCenterPlay = styled.div.attrs({ 
})`      
    width: 108px;
    height: 108px;
    border-radius: 54px;
    margin: 0 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--secondary-color);
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.2);
    }
`;
export const VideoCenterPlayIcon = styled.img.attrs({ 
    src:'/icons/play.svg',
    width: 40
})`      
`;










 



export const VideoBottomContent = styled.div.attrs({ 
})`
`;
export const VideoBottomAction = styled.div.attrs({ 
})` 
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: var(--secondary-color);

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;

    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;
export const VideoBottomActionIcon = styled.img.attrs(props => ({ 
    src:`/icons/chevron.svg`,
}))`
    ${
        props => props.active ? `` : `
            transform: rotate(180deg);
        `
    }
`;
export const VideoBottomWrapper = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    gap: 20px;
    justify-content: center;
`;
export const VideoBottomVideo = styled.div.attrs({ 
})`
    width: 180px;
    height: 100px;

    background: url(${ props => props.image }) no-repeat center center / cover ;
    transition: all .3s ease;
    cursor: pointer;
    &:hover{
        border: 3px solid var(--secondary-color);
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;