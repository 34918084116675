import { GET, POST, PUT, DELETE } from './api'

export const CreateProfile = async (params) => {
    return await POST(`/profiles`, params, true);
}

export const ReadProfiles = async () => {
    return await GET(`/profiles?populate[0]=picture`, true);
}

export const ReadProfilePictures = async () => {
    return await GET(`/profile-pictures`, true);
} 

export const UpdateProfile = async (params, id) => {
    return await PUT(`/profiles/${id}`, params, true);
}

export const DeleteProfile = async (id) => {
    return await DELETE(`/profiles/${ id }`, true);
} 