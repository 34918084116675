import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
 
 




export const DashboardSearcher = styled.div.attrs({ 
})`             
    padding: 16px 20px;
    background: var(--white-color);

    display: flex;

    margin-bottom: 20px;
    ${
        props => props.outline ? `
            border: 1px solid var(--shadow-color);
        ` : ``
    }
`;
export const SearchItem = styled.div.attrs({ 
})`             
    width: 36px;
    height: 36px;
    border-radius: 18px;

    background: var(--secondary-color);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover{
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`;
export const SearchItemIcon = styled.img.attrs(props => ({ 
    src: `/icons/${ props.icon }.svg`,
    width: 14
}))`             
`;
export const SearcherInput = styled.input.attrs({ 
})`             
    flex:1;
    background: none;
    border: none;
    outline: none;
    padding: 0 16px;

    font-size: 22px;
    color: var(--black-color);
`;

 