import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
  
export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;  

export const DashboardSpacer = styled.div.attrs({ 
})`             
    margin-top: 20px;
`;



export const PageTitle = styled.div.attrs({ 
})`              
    font-size: 30px;
    font-weight: bold;
    color: var(--black-color);
    margin-bottom: 40px;
    
    text-align: center;
`;
export const PageSubtitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: var(--black-color);
    margin-bottom: 20px; 
`;
export const PageText = styled.div.attrs({ 
})`    
    font-size: 14px;
    color: var(--black-color);
    margin-bottom: 20px;           
`;