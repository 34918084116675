import { GET } from './api'

/*
    const AvailableLocales = [
        walkthrough.image
        login
        montly_plan
        yearly_plan
        checkout
        success_purchase
        failed_purchase
        creating_profile
        choose_profile
    ]
*/

export const ReadBackground = async (locale) => {
    return await GET(`/background-manager?populate[0]=${ locale }`, false);
} 
