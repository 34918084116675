import styled from 'styled-components';


export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding: 0px 0 0;

    background: var(--primary-color);
`; 


// Container principal para a seção de Perguntas Frequentes
export const FAQContainer = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
`;

// Título da seção de Perguntas Frequentes
export const FAQTitle = styled.div.attrs({  
})`          
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

// Estilo para cada pergunta
export const Question = styled.div`
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #e0e0e0;
  }
`;

// Texto da pergunta
export const QuestionText = styled.h3`
  font-size: 1.25rem;
  margin: 0;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

// Estilo da resposta (que será revelada ao clicar)
export const Answer = styled.div`
  padding: 10px 15px;
  font-size: 1rem;
  color: #555;
  background-color: #fafafa;
  border-left: 4px solid #333;
  border-radius: 0 0 10px 10px;
  margin-top: -10px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
