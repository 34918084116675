import React from "react";  
import { useHistory } from "react-router-dom";

import {   

    VideosContent,
    VideosContentTitle,
    VideosContentRow, 
    VideosContentCircle,
    VideosContentCircleSpace,
    VideosContentCircleIcon, 
    VideoContentVideo,
    VideoContentVideoDecoration,
    VideosContentRowScroll

} from "./styled";  

export default function DashboardRowVideos({ title, icon, image, videos, wrapper }){  
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
    const openVideo = (mit) => {
       // navigate(`dashboard/player/${mit.id}`)  faglimar substituiu esse pelo de baixo para tocar o vídeo ao clicar no thumb
       navigate(`dashboard/player/${mit.id}/running`)
    }

    return ( 
        <> 
            <VideosContent>
                <VideosContentTitle>{ title }</VideosContentTitle>
                <VideosContentRowScroll>
                    <VideosContentRow gap={!icon} wrapper={wrapper}>
                        {
                            (icon || image) ? <>
                                <VideosContentCircle image={image}>
                                    { icon ? <VideosContentCircleIcon icon={icon}/> : null }
                                </VideosContentCircle>
                            </> : wrapper ? null : <VideosContentCircleSpace />
                        }
                        {
                            videos.map((mit, mik) => 
                                <VideoContentVideo key={mik} image={mit.cover} onClick={() => openVideo(mit)}>
                                    <VideoContentVideoDecoration />
                                </VideoContentVideo>
                            )
                        }
                    </VideosContentRow>
                </VideosContentRowScroll>
            </VideosContent> 
        </>
    );
}