import React from 'react';
import { Container, CommomTitle, ImagesContainer, Image, CommomCentred, BannerCenterActions, BannerTitle, ColorLink, Card, CircleImageContainer, CardText, InfoList  } from './styled';

import Button from "components/Button";
import { useHistory } from 'react-router-dom';

// // Caminhos das imagens (substitua com os caminhos corretos)
// const images = [
//     { src: '/images/Joinkids_plano_mensal.png', text: 'Texto 1' },
//     { src: '/images/Joinkids_plano_anual.png', text: 'Texto 2' }
   
//   ];

// const joinkids_image1 = '/images/Joinkids_plano_mensal.png';
// const joinkids_image2 = '/images/Joinkids_plano_anual.png';

const cardsData = [
    {
      src: '/images/Joinkids_plano_mensal_02.png',
      text: 'Plano Mensal por R$ 19,70',
      list: ['Acesso ilimitado aos Conteúdos', 'Até 03 crianças ao mesmo tempo', 'Cancele a qualquer momento', 'Atividades no site para Imprimir', 'Livro Digital Animado para leitura']
    },
    {
      src: '/images/Joinkids_plano_anual_02.png',
      text: 'Plano Anual por R$ 154,80',
      list: ['Economia de R$ 81,60 em relação ao Plano Mensal','Acesso ilimitado aos Conteúdos', 'Até 03 crianças ao mesmo tempo', 'Atividades no site para Imprimir', 'Livro Digital Animado para leitura']
    }
];


const ResponsiveImagesSection = () => {

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
  return (
    <Container>
      {/* Título */}
      <CommomTitle>Conheça nossos Planos</CommomTitle>

      {/* Container das Imagens */}
      <ImagesContainer>
        {cardsData.map((item, index) => (
          <Card key={index}>
            {/* Imagem dentro do círculo */}
            <CircleImageContainer>
              <img src={item.src} alt={`Imagem ${index + 1}`} />
            </CircleImageContainer>

            {/* Texto abaixo da imagem */}
            <CardText>{item.text}</CardText>

            {/* Lista de informações */}
            <InfoList>
              {item.list.map((info, idx) => (
                <li key={idx}>{info}</li>
              ))}
            </InfoList>
            
            
          </Card>
        ))}


        {/* <Image src={joinkids_image1} alt="Imagem 1" />
        <Image src={joinkids_image2} alt="Imagem 2" /> */}
      </ImagesContainer>

      <CommomTitle>Assine! E proteja a sua Família</CommomTitle>

      <BannerCenterActions>
        <Button green onClick={() => navigate('planos')}>Assinar Agora</Button>
        <BannerTitle><a href="https://wa.me/5592982168732?text=Eu%20quero%20saber%20mais!"><ColorLink><Button secondary>Saiba mais</Button></ColorLink></a></BannerTitle>
      </BannerCenterActions>

    </Container>
  );
};

export default ResponsiveImagesSection;

//<Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/>
// na linha 32 <Player autoplay={true}>