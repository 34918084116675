import React, { useContext, useEffect, useState } from "react";  

import {    

} from "./styled";

import ContainerAuthenticated from "containers/Authenticated"; 
import DashboardRowVideos from "components/Dashboard/RowVideos";
import DashboardMenu from "components/Dashboard/Menu";
import CarouselBanner from "components/Dashboard/CarouselBanner";
import { availableCatagories, availableVideos } from "utils/mock";
import { CoreContext } from "context/CoreContext";
import { ReadHome, ReadHomeTabs, ReadTabCatalog, ReadTabPlaylist } from "services/catalog";
import { parseCollection } from "utils/parser";
import { Load, CenterLoad } from "ui/styled";

export default function DashboardHome(){   

    const { currentCategory } = useContext(CoreContext)

    const [banners, setBanners] = useState([])
    const [tabs, setTabs] = useState([])
    const [loading, setLoading] = useState(true)
    const [begining, setBegining] = useState(true)
    const [categoryCollections, setCategoryCollections] = useState([])

    const init = async () => {
        const home = await ReadHome()
        const tabs = await ReadHomeTabs()

        if(home?.data?.attributes?.featured?.length){
            setBanners(home?.data?.attributes?.featured)
        } 

        if(tabs?.data?.length){
            setTabs([...tabs?.data])
        } 
    }

    const initCategory = async () => {
        if((currentCategory > 0 && currentCategory < 4) && !tabs[currentCategory-1]?.id){ return ;}
        setLoading(true)
        const requests = []
        if(currentCategory === 0){
            requests.push(ReadTabPlaylist())
        }
        if(currentCategory > 0 && currentCategory < 4){
            if(tabs[currentCategory-1]?.id){
                requests.push(ReadTabCatalog(tabs[currentCategory-1]?.id))
            }
        }
        if(currentCategory === 4){
            // showing more, nothing to do
        }
        const result =  await Promise.all(requests)
        if(result?.[0]?.data?.length){
            setCategoryCollections(result?.[0]?.data?.map(parseCollection)) 

        }else{
            setCategoryCollections([])
        }
        setLoading(false)
        setBegining(false)
    }
 
    useEffect(() => {
        if(tabs?.length){ initCategory() }
    }, [currentCategory])
 
    useEffect(() => {
        initCategory()
    }, [tabs]) 
    
    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated primary page={'home'}>  

                <CarouselBanner banners={banners}  />
                {
                    begining ? null :
                    <DashboardMenu tabs={tabs}  /> 
                }


                { loading ? 
                    <CenterLoad>
                        <Load white /> 
                    </CenterLoad>
                : null }

                { 
                    categoryCollections?.length ? <>
                        {
                            categoryCollections.map((cat, kcat) => 
                                <DashboardRowVideos key={kcat} { ...cat } wrapper={categoryCollections?.length === 1} /> 
                            )
                        }
                    </> : null
                }
                
            </ContainerAuthenticated> 
        </>
    );

}