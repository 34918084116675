import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding: 0px 0 0;

    background: var(--primary-color);
`; 

export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 30px;
    font-weight: bold;
    line-height: 1em;
    color: var(--white-color);
    margin: 0;
    
    width: 100%;
    max-width: 360px;
`;  
 

export const CommomText = styled.div.attrs({  
})`          
    font-size: 18px;
    line-height: 1.5em;
    color: var(--white-color);
    margin: 15px 0 0 0;

    width: 100%;
    max-width: 360px;
`;  
 


export const CommomImageBanner = styled.img.attrs({  
    src:'/images/p4_gestantes.png'
})`           
    width: 100%;
    max-width: 540px;

    @media(max-width: 767px){
        margin-top: 20px;
        margin-botton: 20px;
    }
`;  
 
export const CommomCentred = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    ${
        props => props.spaced ? `
            padding-top: 20px;
        ` : ``
    }
`; 