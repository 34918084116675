import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  
import { 
    DashboardSpacer,
    BannerCenterContent,
    BannerLogo,
    BannerCard,
    BannerText,
    BannerText2,
    BannerSubtext,
    ColorLink
} from "./styled"; 

import ContainerAuthenticated from "containers/Authenticated";
import DashboardMenu from "components/Dashboard/Menu"; 
import Button from "components/Button";
// import { CoreContext } from "context/CoreContext";

// export default function DashboardDownloads(){   

//     const { reloadDownloads, downloads } = useContext(CoreContext)

//     useEffect(() => {
//         reloadDownloads()
//     }, [])

export default function Banner(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <ContainerAuthenticated primary page={'Downloads'}> 
            <DashboardSpacer>
            
              
                        <Row>
                            <Col md={{ size: 4 }}></Col>
                            <Col md={{ size: 4 }}> 
                                <BannerCard>
                                    <BannerCenterContent>
                                        <BannerLogo />
                                        
                                    <BannerText><strong>Atividades Extras</strong></BannerText>
                                        <BannerSubtext>-------------------------------------------------</BannerSubtext>
                                        <BannerSubtext>Clique na atividade abaixo para imprimir</BannerSubtext>
                                        <BannerSubtext>-------------------------------------------------</BannerSubtext>
                                        
                                        <BannerSubtext>*</BannerSubtext>
                                        <BannerSubtext>JoinKids: Caderno de Colorir - Vol. 01:</BannerSubtext>
                                            <BannerText2>Lindas ilustrações para colorir</BannerText2>
                                    <BannerText><a href="/docs/JoinKids_Caderno_de_Colorir_Vol01.pdf"><ColorLink>Caderno de Colorir - Vol.01</ColorLink></a></BannerText>
                                    <BannerSubtext>*</BannerSubtext>
                                        
                                        <BannerSubtext>Material do Filme: Rabisco e a Verdadeira Páscoa:</BannerSubtext>
                                            <BannerText2>Cubra e Pinte o Pequeno Rabisco</BannerText2>
                                    <BannerText><a href="/docs/rabisco.pdf"><ColorLink>O Pequeno Rabisco</ColorLink></a></BannerText>
                                    <BannerSubtext>*</BannerSubtext>

                                        <BannerSubtext>Brinde Exclusivo Natal de Jesus:</BannerSubtext>
                                            <BannerText2>Pinte, recorte, monte e conte a História</BannerText2>
                                    <BannerText><a href="/docs/Conte_Paper_Natal_de_Jesus.pdf"><ColorLink>Conte Paper: Narrando Histórias [Natal]</ColorLink></a></BannerText>
                                    <BannerSubtext>*</BannerSubtext>
                                    <BannerSubtext>Material da Série JoinMaker:</BannerSubtext>
                                    <BannerText><a href="/docs/Ep1_Tempo_de_Louvar.pdf"><ColorLink>JoinMaker Ep1: Tempo de Louvar</ColorLink></a></BannerText>
                                    <BannerText><a href="/docs/Ep2_Deus_conta_as_Estrelas.pdf"><ColorLink>JoinMaker Ep2: Deus conta as Estrelas</ColorLink></a></BannerText>
                                    <BannerText><a href="/docs/Ep3_Brilhe_Sua_Luz.pdf"><ColorLink>JoinMaker Ep3: Brilhe Sua Luz</ColorLink></a></BannerText>
                                    <BannerText><a href="/docs/Ep4_Coisa_Maravilhosas_.pdf"><ColorLink>JoinMaker Ep4: Coisa Maravilhosas</ColorLink></a></BannerText>
                                        <BannerSubtext>*</BannerSubtext>
                                        <BannerSubtext>Material do vídeo: O Segredo do Natal:</BannerSubtext>
                                        <BannerText><a href="/docs/natal_joinkids.pdf"><ColorLink>O Segredo do natal</ColorLink></a></BannerText>
                                        <BannerSubtext>*</BannerSubtext>
                                        <BannerSubtext>Material do Filme: Rabisco e o Verdadeiro Natal:</BannerSubtext>
                                        <BannerText><a href="/docs/joinkids_desenho_rabisco.pdf"><ColorLink>Rabisco e o Menino Jesus</ColorLink></a></BannerText>
                                        <BannerSubtext>*</BannerSubtext>
                                        <BannerSubtext>Material Armadura de Deus:</BannerSubtext>
                                        <BannerText><a href="/docs/CartilhaJoinkidsArmaduradeDeus.pdf"><ColorLink>Cartilha: A Armadura de Deus</ColorLink></a></BannerText>    
                                        <BannerSubtext>----------------------</BannerSubtext>
                                        
                                    </BannerCenterContent>
                                </BannerCard>
                            </Col>
                            <Col md={{ size: 4 }}>  
                            </Col>      
                        </Row>
            </DashboardSpacer>
            </ContainerAuthenticated>
        </>
    );
}







//     return ( 
//         <> 
//             <ContainerAuthenticated primary page={'Downloads'}> 
//             <DashboardSpacer>
//             {/* <BannerImage> */}
//                 <BannerOut />
//                 <BannerContent>
//                     <Container>
                       
//                         <Row>
//                             <Col md={{ size: 4 }}></Col>
//                             <Col md={{ size: 4 }}> 
//                                 <BannerCard>
//                                     <BannerCenterContent>
//                                         {/* <BannerLogo /> */}
                                   
//                                     <BannerText>Meus Downloads:</BannerText>
                                
//                                     <BannerText><a href="/docs/Ep1_Tempo_de_Louvar.pdf"><strong>JoinMaker_Ep1_Tempo_de_Louvar</strong></a></BannerText>
//                                     <BannerText><a href="/docs/Ep2_Deus_conta_as_Estrelas.pdf"><strong>JoinMaker_Ep2_Deus_conta_as_Estrelas</strong></a></BannerText>
//                                     <BannerText><a href="/docs/Ep3_Brilhe_Sua_Luz.pdf"><strong>JoinMaker_Ep3_Brilhe_Sua_Luz</strong></a></BannerText>
//                                     <BannerText><a href="/docs/Ep4_Coisa_Maravilhosas_.pdf"><strong>JoinMaker_Ep4_Coisa_Maravilhosas</strong></a></BannerText>
                                    
//                                     </BannerCenterContent>
//                                 </BannerCard>
//                             </Col>
//                             <Col md={{ size: 4 }}>
                                
//                             </Col>
                            
//                         </Row>
//                     </Container>
//                 </BannerContent>
//             {/* </BannerImage> */}
//             </DashboardSpacer>
//             </ContainerAuthenticated>
//         </>
//     );
// }


